import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { css, ThemeProvider } from "styled-components";
import { WEBSITE_TITLE } from "@config/main";
import GlobalStyles from "@theme/GlobalStyles";
import { theme } from "@theme/theme";
import SmoothScrollbar from "smooth-scrollbar";
import Loader from "@components/atoms/Loader/Loader";
import LoadingPlugin from "@plugins/LoadingPlugin";
import { Scrollbar } from "smooth-scrollbar/scrollbar";
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll";
import { MainContext } from "@context/index";
import { useParallax } from "@hooks/useParallax";
import Navigation from "@components/molecules/Navigation/Navigation";
import Footer from "@components/molecules/Footer/Footer";
import { easingFunction } from "@theme/easing";
import LightboxesProvider from "@context/LightboxesProvider";
import Lightbox from "@components/atoms/Lightbox/Lightbox";
import { useWindowSize } from "react-use";
import SweetScroll from "sweet-scroll";
import { graphql, useStaticQuery } from "gatsby";
import CookieNotice from "@rendpro/react-cookie-notice";
import { Location } from "../types/location";

SmoothScrollbar.use(LoadingPlugin, OverscrollPlugin);

const BasicTemplate: FC<Props> = ({
  children,
  header: Header,
  isAnotherThanIndex,
  location,
  title,
}) => {
  const [isLoading, setLoading] = useState<boolean>(!isAnotherThanIndex);
  const [canBeDisplayedMessenger, setCanBeDisplayedMessenger] =
    useState<boolean>(false);
  const { width } = useWindowSize();
  const [isLoaderExited, setLoaderExited] =
    useState<boolean>(isAnotherThanIndex);
  const [smoothScrollbar, setSmoothScrollbar] = useState<Scrollbar>();
  const [scroller, setScroller] = useState<SweetScroll>();
  const {
    file: {
      childImageSharp: {
        gatsbyImageData: {
          images: {
            fallback: { src: ogImage },
          },
        },
      },
    },
  } = useStaticQuery(query);
  useParallax(smoothScrollbar);

  const initSmoothScrollbar = () => {
    setSmoothScrollbar(
      SmoothScrollbar.init(document.querySelector("#scroll-wrapper"), {
        damping: 0.05,
        // delegateTo: document,
        // wheelEventTarget: document.body,
        plugins: {
          overscroll: { damping: 0.1 },
        },
      })
    );
  };

  useEffect(() => {
    const getVh = () => {
      const vh = typeof window !== "undefined" && window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    getVh();

    window.addEventListener("resize", getVh);

    setScroller(
      new SweetScroll({
        duration: 1500,
        offset:
          // eslint-disable-next-line no-nested-ternary
          typeof window !== "undefined" && window.innerWidth >= 1025
            ? -80
            : -60,
        easing: easingFunction,
        trigger: "[data-sweet-scroll]",
      })
    );

    return () => {
      window.removeEventListener("resize", getVh);
    };
  }, []);

  useEffect(() => {
    initSmoothScrollbar();

    const timeout = setTimeout(() => setLoading(false), 1000);

    return () => {
      clearTimeout(timeout);
      smoothScrollbar?.destroy();
      setSmoothScrollbar(undefined);
    };
  }, [typeof window !== "undefined" && window.location.pathname]);

  useEffect(() => {
    if (!smoothScrollbar && width >= 1025) {
      initSmoothScrollbar();
    } else if (smoothScrollbar && width <= 1024) {
      smoothScrollbar?.destroy();
      setSmoothScrollbar(undefined);
    }
  }, [width, smoothScrollbar]);

  useEffect(() => {
    smoothScrollbar?.updatePluginOptions("loading", {
      loading: !isLoaderExited,
    });
  }, [isLoaderExited, smoothScrollbar]);

  useEffect(() => {
    if (location?.state?.scrollTo && isLoaderExited) {
      const elementTarget = document.querySelector(location?.state?.scrollTo);
      const distanceFromTop =
        (smoothScrollbar?.scrollTop || window.scrollY) +
        elementTarget.getBoundingClientRect().top;

      if (smoothScrollbar?.scrollTo) {
        smoothScrollbar?.scrollTo(0, distanceFromTop - 80, 2000, {
          easing: easingFunction,
        });
      } else {
        scroller.to(distanceFromTop);
      }

      typeof window !== "undefined" && window.history.replaceState(null, "");
    }
  }, [location?.state?.scrollTo, smoothScrollbar, isLoaderExited]);

  useEffect(() => {
    const chatbox = document.getElementById("fb-customer-chat");
    chatbox.setAttribute("page_id", process.env.GATSBY_FACEBOOK_PAGE_ID);
    chatbox.setAttribute("attribution", "biz_inbox");

    /* eslint-disable */
    // @ts-ignore
    window.fbAsyncInit = function () {
      // @ts-ignore
      FB.init({
        xfbml: true,
        version: "v12.0",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      // @ts-ignore
      js.id = id;
      // @ts-ignore
      js.src = "https://connect.facebook.net/pl_PL/sdk/xfbml.customerchat.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
    /* eslint-enable */
  }, []);

  const readyTitle = `${title || ""}${WEBSITE_TITLE}`;

  return (
    <div>
      <GlobalStyles />
      <Helmet>
        <title>{readyTitle}</title>
        <html lang="pl" />
        <meta
          name="description"
          content="Nowe mieszkania na sprzedaż w Lewiczynie, niedaleko Mławy. Sprawdź cenę nieruchomości. Na sprzedaż są domy parterowe."
        />

        <meta
          name="facebook-domain-verification"
          content="03jlxmjx28pcgr67nxdw60f4nvghx6"
        />

        <meta property="og:image" content={ogImage} />
        <meta property="og:title" content={readyTitle} />
        <meta
          property="og:description"
          content="Nowe mieszkania na sprzedaż w Lewiczynie, niedaleko Mławy. Sprawdź cenę nieruchomości. Na sprzedaż są domy parterowe."
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <MainContext.Provider
          value={{
            isLoading,
            isLoaderExited,
            smoothScrollbar,
            sweetScroll: scroller,
          }}
        >
          <Loader loaded={!isLoading} setLoaderExited={setLoaderExited} />
          <LightboxesProvider>
            <Navigation isAnotherThanIndex={isAnotherThanIndex} />
            <div id="scroll-wrapper">
              <div>
                {Header && (
                  <header>
                    <Header />
                  </header>
                )}
                <main>{children}</main>
                <Footer />
              </div>
            </div>
            <Lightbox id="visualizations" />
            <Lightbox id="interior" />
          </LightboxesProvider>
          <CookieNotice
            text="Ta strona używa plików cookie w celu usprawnienia i ułatwienia dostępu do serwisu oraz prowadzenia danych statystycznych. Dalsze korzystanie z tej witryny oznacza akceptację tego stanu rzeczy."
            buttonText="Akceptuję"
            links={[
              {
                name: "Polityka prywatności",
                link: "https://jakwylaczyccookie.pl/polityka-cookie/",
              },
              {
                name: "Jak wyłączyć cookie",
                link: "https://jakwylaczyccookie.pl/jak-wylaczyc-pliki-cookies/",
              },
              {
                name: "Cyberbezpieczeństwo",
                link: "https://nety.pl/cyberbezpieczenstwo",
              },
            ]}
            customStyles={css`
              & {
                z-index: 9999;
                box-shadow: 0 5px 50px -10px rgba(0, 0, 0, 0.2);
              }
              button {
                font-family: "Roboto", sans-serif !important;
                border-radius: 0;
              }
            `}
            foregroundColor="#191E1C"
            backgroundColor="#fff"
            linksColor="#285A53"
            buttonBackgroundColor="#191E1C"
            buttonColor="#fff"
            onHide={() => setCanBeDisplayedMessenger(true)}
          />

          <div
            id="fb-root"
            style={{
              transition: "opacity .2s ease-in-out",
              ...(canBeDisplayedMessenger
                ? { pointerEvents: "all", opacity: 1 }
                : { pointerEvents: "none", opacity: 0 }),
            }}
          />
          <div id="fb-customer-chat" className="fb-customerchat" />
        </MainContext.Provider>
      </ThemeProvider>
    </div>
  );
};

const query = graphql`
  {
    file(name: { eq: "hero1" }) {
      childImageSharp {
        gatsbyImageData(quality: 70, formats: JPG, layout: FIXED, width: 1200)
      }
    }
  }
`;

interface Props {
  header?: FC;
  isAnotherThanIndex?: boolean;
  location?: Location;
  title?: string;
}

export default BasicTemplate;
