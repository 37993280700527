import React, { FC } from "react";
import styled from "styled-components";
import BusinessLogo from "@components/atoms/BusinessLogo/BusinessLogo";
import Logo from "@components/atoms/Logo/Logo";
import { NAVIGATION_ITEMS } from "@config/main";
import ScrollTo from "@components/atoms/ScrollTo/ScrollTo";
import facebookIcon from "@iconify/icons-la/facebook-f";
import instagramIcon from "@iconify/icons-la/instagram";
import { Icon } from "@iconify/react";
import { cssEaseInOutCirc, cssEaseOutExpo } from "@theme/easing";
import { graphql, useStaticQuery } from "gatsby";

const StyledWrapper = styled.div`
  background: ${({ theme }) => theme.secondaryBackground};
  padding: 66px 0 35px;
`;

const StyledInnerWrapper = styled.div`
  width: 85%;
  max-width: 1480px;
  margin: auto;
`;

const StyledFirstRow = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.washLight};
  padding-bottom: 25px;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding-bottom: 30px;
  }
`;

const StyledSecondRow = styled.div`
  padding-top: 25px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    padding-top: 30px;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledBusinessLogo = styled(BusinessLogo)`
  width: 200px;
  height: auto;

  @media (max-width: 1024px) {
    order: 1;
    margin-bottom: 50px;
  }
`;

const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: -15px 40px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 1024px) {
    order: 3;
    flex-direction: column;
    margin: 50px 0 0;
  }
`;

const StyledItem = styled.li`
  margin-right: 40px;
  font-weight: 500;
  margin-top: 15px;

  :last-of-type {
    margin-right: 0;
  }

  @media (max-width: 1024px) {
    margin: 0 0 30px;
    text-align: center;
    font-size: 1.8rem;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledLogo = styled(Logo)`
  width: 120px;
  height: auto;

  @media (max-width: 1024px) {
    order: 2;
  }
`;

const StyledAuthor = styled.div`
  width: 270px;
  a {
    text-decoration: none;
    font-weight: 700;
    color: inherit;

    :hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 1024px) {
    order: 2;
    width: unset;
    margin-bottom: 30px;
  }
`;

const StyledCopyright = styled.div`
  text-align: center;

  @media (max-width: 1024px) {
    order: 3;
  }
`;

const StyledSocialMediaWrapper = styled.div`
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 270px;
  justify-content: flex-end;

  a {
    font-size: 2.4rem;
    color: ${({ theme }) => theme.foreground};

    :first-of-type {
      margin-left: 13px;
      margin-right: 8px;
    }

    :last-of-type {
      font-size: 2.8rem;
    }
  }

  @media (max-width: 1024px) {
    order: 1;
    width: unset;
    margin-bottom: 30px;
  }
`;

const StyledLegalNote = styled.span`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.washHeavy};
  width: 100%;
  text-align: center;
  display: block;
  margin-top: 30px;
`;

const StyledScrollTo = styled(ScrollTo)`
  color: ${({ theme }) => theme.foreground};
  text-decoration: none;
  transition: color 0.5s ${cssEaseInOutCirc};
  position: relative;

  ::before {
    width: 90%;
    height: 2px;
    background: ${({ theme }) => theme.foreground};
    position: absolute;
    content: "";
    bottom: -4px;
    transition: transform 0.5s ${cssEaseOutExpo};
    transform: scaleX(0);
  }

  :hover::before {
    transform: scaleX(1);
  }
`;

const Footer: FC = () => {
  const { datoCmsSetting } =
    useStaticQuery<{ datoCmsSetting: { facebook: string; instagram: string } }>(
      query
    );

  return (
    <StyledWrapper>
      <StyledInnerWrapper>
        <StyledFirstRow>
          <StyledBusinessLogo />
          <StyledList>
            {NAVIGATION_ITEMS.map(({ href, name }) => (
              <StyledItem key={href}>
                <StyledScrollTo href={href}>{name}</StyledScrollTo>
              </StyledItem>
            ))}
          </StyledList>
          <StyledLogo />
        </StyledFirstRow>
        <StyledSecondRow>
          <StyledAuthor>
            Stworzone z ❤️ przez <a href="https://rend.pro/offer/investment-website/">RendPro.com</a>
          </StyledAuthor>
          <StyledCopyright>2021 &copy; Osiedle Zielone Wzgórza</StyledCopyright>
          <StyledSocialMediaWrapper>
            Obserwuj nas:
            <a
              href={datoCmsSetting.facebook}
              target="_blank"
              rel="noreferrer"
              aria-label="Odwiedź nas na Facebooku"
            >
              <Icon icon={facebookIcon} />
            </a>
            <a
              href={datoCmsSetting.instagram}
              target="_blank"
              rel="noreferrer"
              aria-label="Odwiedź nas na Instagramie"
            >
              <Icon icon={instagramIcon} />
            </a>
          </StyledSocialMediaWrapper>
        </StyledSecondRow>
        <StyledLegalNote>
          Treści znajdujące się na stronie internetowej nie stanowią oferty w
          rozumieniu przepisów prawa.
        </StyledLegalNote>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    datoCmsSetting {
      facebook
      instagram
    }
  }
`;

export default Footer;
