import React, { FC, HTMLProps } from "react";
import styled from "styled-components";
import { cssEaseOutExpo } from "@theme/easing";
import { Icon } from "@iconify/react";

const StyledButton = styled.button<{ $iconPosition: "left" | "right" }>`
  display: inline-flex;
  padding: ${({ $iconPosition }) =>
    $iconPosition === "right" ? "6px 6px 6px 15px" : "6px 15px 6px 6px"};
  background: ${({ theme }) => theme.primary};
  color: #fff;
  text-decoration: none;
  align-items: center;
  font-weight: 500;
  transition: background 0.5s ${cssEaseOutExpo};
  border: 0;
  cursor: pointer;

  div {
    background: #fff;
    width: 45px;
    height: 45px;
    display: flex;
    margin: ${({ $iconPosition }) =>
      $iconPosition === "right" ? "0 0 0 20px" : "0 20px 0 0"};
    color: ${({ theme }) => theme.primary};
    align-items: center;
    justify-content: center;
    font-size: 2.2rem;
    transition: color 0.5s ${cssEaseOutExpo};
  }

  :hover {
    background: ${({ theme }) => theme.primaryDarker};

    div {
      color: ${({ theme }) => theme.primaryDarker};
    }
  }

  :active {
    transform: scale(0.9);
  }
`;

const Button: FC<Props> = ({
  children,
  icon,
  iconPosition = "right",
  ...props
}) => (
  <StyledButton $iconPosition={iconPosition} {...(props as any)}>
    {iconPosition === "left" && (
      <div>
        <Icon icon={icon} />
      </div>
    )}
    {children}{" "}
    {iconPosition === "right" && (
      <div>
        <Icon icon={icon} />
      </div>
    )}
  </StyledButton>
);

interface Props extends HTMLProps<HTMLButtonElement> {
  icon: any;
  iconPosition?: "left" | "right";
  as?: any;
  href?: string;
  to?: string;
  rel?: string;
}

export default Button;
