import React, { FC } from "react";
import LogoSvg from "@assets/svg/business_logo.svg";

const BusinessLogo: FC<Props> = ({ className, ...props }) => (
  <LogoSvg className={className} {...props} />
);

interface Props extends React.SVGProps<SVGElement> {
  className?: string;
}

export default BusinessLogo;
