import React, { FC, useContext } from "react";
import ReactSpringLightbox from "react-spring-lightbox";
import styled from "styled-components";
import closeIcon from "@iconify/icons-fontisto/close-a";
import { Icon } from "@iconify/react";
import { useButtonEffects } from "@hooks/useButtonEffects";
import { arrowLeftIcon, arrowRightIcon } from "@assets/icons";
import { rippleAnimation } from "@theme/repeatedStyles";
import { LightboxContext } from "@context/index";

const StyledReactSpringLightbox = styled(ReactSpringLightbox)`
  z-index: 999999 !important;
  background: rgba(0, 0, 0, 0.5);
  .lightbox-image-pager {
    cursor: grab;
  }
`;

const StyledHeader = styled.div`
  width: 100%;
  height: 60px;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  color: #fff;
  div {
    font-size: 2.2rem;
    font-weight: 700;
  }
  button {
    width: 40px;
    color: #fff;
    cursor: pointer;
    height: 40px;
    background: transparent;
    font-size: 2.2rem;
    border: 0;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.2s ease-in-out;
    :hover {
      color: #d2d2d2;
    }
  }
  @media (max-width: 574px) {
    height: 50px;
    padding: 0 25px;
    div {
      font-size: 1.8rem;
    }
    button {
      font-size: 1.8rem;
    }
  }
`;

const StyledArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  width: 80px;
  height: 80px;
  cursor: pointer;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  background: transparent;
  transition: background 0.2s ease-in-out, transform 0.2s ease-out;
  transform-origin: center center;
  overflow: hidden;
  font-size: 2.5rem;
  :first-of-type {
    left: 20px;
  }
  :last-of-type {
    right: 20px;
  }
  :hover {
    background: #000;
  }
  :active {
    transform: translateY(-50%) scale(0.9);
  }
  .ripple {
    width: 120px;
    height: 120px;
    position: absolute;
    background: #fff;
    border-radius: 50%;
    transform: scale(0);
    pointer-events: none;
    opacity: 0;
    animation: ${rippleAnimation} 0.6s ease-out;
    transform-origin: center center;
    @media (min-width: 1025px) {
      width: 260px;
      height: 260px;
    }
  }
  @media (max-width: 574px) {
    width: 60px;
    height: 60px;
  }
`;

const Header: FC<{
  length: number;
  currentIndex: number;
  onClose: () => void;
}> = ({ length, currentIndex, onClose }) => (
  <StyledHeader>
    <div>
      {currentIndex + 1} / {length}
    </div>
    <button aria-label="Zamknij" onClick={onClose}>
      <Icon icon={closeIcon} />
    </button>
  </StyledHeader>
);

const ArrowButton: FC<React.HTMLProps<HTMLButtonElement>> = ({
  children,
  ...props
}) => {
  const { onMouseDown } = useButtonEffects();

  return (
    <StyledArrowButton {...(props as any)} onMouseDown={onMouseDown}>
      {children}
    </StyledArrowButton>
  );
};

const Lightbox: FC<{
  id: "visualizations" | "interior";
}> = ({ id }) => {
  const { interiorLightbox, visualizationsLightbox } =
    useContext(LightboxContext);

  const lightbox =
    id === "visualizations" ? visualizationsLightbox : interiorLightbox;

  const {
    images,
    setCurrentImage,
    currentImage,
    setLightboxActive,
    isLightboxActive,
    swiper,
  } = lightbox;

  const slideTo = (index: number) => swiper?.slideToLoop(index, 1000);

  const onPrev = () =>
    setCurrentImage((prevState) =>
      currentImage === 0 ? images.length - 1 : prevState - 1
    );

  const onNext = () =>
    setCurrentImage((prevState) =>
      currentImage === images.length - 1 ? 0 : prevState + 1
    );

  const onClose = () => {
    slideTo(currentImage);
    setLightboxActive(false);
  };

  return (
    <StyledReactSpringLightbox
      isOpen={isLightboxActive}
      onClose={() => setLightboxActive(false)}
      onNext={onNext}
      onPrev={onPrev}
      images={images}
      currentIndex={currentImage}
      renderHeader={() => (
        <Header
          length={images?.length}
          currentIndex={currentImage}
          onClose={onClose}
        />
      )}
      renderNextButton={() => (
        <ArrowButton onClick={onNext} aria-label="Następne zdjęcie">
          <Icon icon={arrowRightIcon} />
        </ArrowButton>
      )}
      renderPrevButton={() => (
        <ArrowButton onClick={onPrev} aria-label="Poprzednie zdjęcie">
          <Icon icon={arrowLeftIcon} />
        </ArrowButton>
      )}
    />
  );
};

export default Lightbox;
