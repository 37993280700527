import React, { FC, useState } from "react";
import { LightboxContext } from "@context/index";
import { ImagesList } from "react-spring-lightbox/dist/types/ImagesList";
import SwiperCore from "swiper";

const LightboxesProvider: FC = ({ children }) => {
  const [visualizationsImages, setVisualizationsImages] =
    useState<ImagesList>();
  const [interiorImages, setInteriorImages] = useState<ImagesList>();
  const [visualizationsSwiper, setVisualizationsSwiper] =
    useState<SwiperCore>();
  const [interiorSwiper, setInteriorSwiper] = useState<SwiperCore>();
  const [visualizationsLightboxActive, setVisualizationsLightboxActive] =
    useState<boolean>();
  const [interiorLightboxActive, setInteriorLightboxActive] =
    useState<boolean>();
  const [visualizationsCurrentImage, setVisualizationsCurrentImage] =
    useState<number>();
  const [interiorCurrentImage, setInteriorCurrentImage] = useState<number>();

  return (
    <LightboxContext.Provider
      value={{
        visualizationsLightbox: {
          images: visualizationsImages,
          setImages: setVisualizationsImages,
          swiper: visualizationsSwiper,
          setSwiper: setVisualizationsSwiper,
          setLightboxActive: setVisualizationsLightboxActive,
          isLightboxActive: visualizationsLightboxActive,
          setCurrentImage: setVisualizationsCurrentImage,
          currentImage: visualizationsCurrentImage,
        },
        interiorLightbox: {
          images: interiorImages,
          setImages: setInteriorImages,
          swiper: interiorSwiper,
          setSwiper: setInteriorSwiper,
          setLightboxActive: setInteriorLightboxActive,
          isLightboxActive: interiorLightboxActive,
          setCurrentImage: setInteriorCurrentImage,
          currentImage: interiorCurrentImage,
        },
      }}
    >
      {children}
    </LightboxContext.Provider>
  );
};

export default LightboxesProvider;
