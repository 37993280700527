export const photovoltaicIcon = {
  body: `
<path d="M68.1427 7.25C61.7363 7.25 56.5427 12.4435 56.5427 18.85C56.55 25.2536 61.7392 30.4427 68.1427 30.45C74.5492 30.45 79.7427 25.2565 79.7427 18.85C79.7427 12.4435 74.5492 7.25 68.1427 7.25ZM68.1427 27.55C63.3378 27.55 59.4427 23.6549 59.4427 18.85C59.4474 14.0471 63.3398 10.1547 68.1427 10.15C72.9477 10.15 76.8427 14.0451 76.8427 18.85C76.8427 23.6549 72.9477 27.55 68.1427 27.55Z" fill="currentColor"/>
<path d="M69.5926 0H66.6926V4.35H69.5926V0Z" fill="currentColor"/>
<path d="M69.5926 33.3501H66.6926V37.7001H69.5926V33.3501Z" fill="currentColor"/>
<path d="M53.6427 17.3999H49.2927V20.2999H53.6427V17.3999Z" fill="currentColor"/>
<path d="M86.9928 17.3999H82.6428V20.2999H86.9928V17.3999Z" fill="currentColor"/>
<path d="M55.8377 4.4961L53.7871 6.54669L56.863 9.62258L58.9136 7.57199L55.8377 4.4961Z" fill="currentColor"/>
<path d="M79.4192 28.0752L77.3687 30.1258L80.4445 33.2017L82.4951 31.1511L79.4192 28.0752Z" fill="currentColor"/>
<path d="M56.8632 28.0736L53.7874 31.1495L55.8379 33.2001L58.9138 30.1242L56.8632 28.0736Z" fill="currentColor"/>
<path d="M80.4462 4.49553L77.3704 7.57141L79.421 9.622L82.4968 6.54612L80.4462 4.49553Z" fill="currentColor"/>
<path d="M78.1159 84.8553L43.3158 21.0553C43.0617 20.5894 42.5734 20.2996 42.0428 20.2998H1.44275C1.33382 20.3022 1.22543 20.3161 1.1194 20.3419C1.1078 20.3419 1.0962 20.3419 1.0846 20.3419C0.943226 20.3797 0.808738 20.4394 0.68585 20.5188C0.6496 20.5427 0.614619 20.569 0.58145 20.5971C0.461825 20.6879 0.356157 20.7957 0.26825 20.9175C0.24795 20.948 0.2204 20.9726 0.20155 21.0045C0.170738 21.0632 0.144457 21.1243 0.12325 21.1872C0.104219 21.2249 0.0873629 21.2637 0.0725004 21.3032C0.0262817 21.4437 0.00181291 21.5903 4.10706e-07 21.7382V31.8998C-0.000180839 32.1407 0.0596317 32.3779 0.174 32.59L29.1667 86.24C29.4203 86.7084 29.9102 87.0002 30.4427 86.9998H76.8428C77.6435 87 78.2929 86.3509 78.2929 85.55C78.2931 85.3073 78.2322 85.0684 78.1159 84.8553ZM65.6995 68.1498H55.9541L48.836 55.0998H58.5815L65.6995 68.1498ZM30.8995 52.1998H19.7041L12.586 39.1498H23.7815L30.8995 52.1998ZM27.086 39.1498H36.8315L43.9495 52.1998H34.204L27.086 39.1498ZM32.4814 55.0998L39.5995 68.1498H28.404L21.286 55.0998H32.4814ZM35.786 55.0998H45.5314L52.6495 68.1498H42.904L35.786 55.0998ZM56.9995 52.1998H47.2541L40.136 39.1498H49.8815L56.9995 52.1998ZM41.1814 23.1998L48.2995 36.2498H38.5541L31.436 23.1998H41.1814ZM28.1314 23.1998L35.2495 36.2498H25.504L18.386 23.1998H28.1314ZM15.0815 23.1998L22.1995 36.2498H11.0041L3.886 23.1998H15.0815ZM31.3127 84.0998L2.89275 31.533V27.4367L33.7995 84.0998H31.3127ZM37.104 84.0998L29.986 71.0498H41.1814L48.2995 84.0998H37.104ZM51.6041 84.0998L44.486 71.0498H54.2315L61.3495 84.0998H51.6041ZM64.6541 84.0998L57.536 71.0498H67.2815L74.3995 84.0998H64.6541Z" fill="currentColor"/>
`,
  width: 87,
  height: 87,
};
