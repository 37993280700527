import React, { FC } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";

const StyledWrapper = styled.a`
  color: inherit;
  text-decoration: none;
  display: block;

  @media (max-width: 840px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledHeadline = styled.h3`
  font-size: 2.4rem;
  margin: 0 0 15px;
  color: ${({ theme }) => theme.primary};

  @media (max-width: 1024px) {
    font-size: 1.8rem;
  }
`;

const StyledContentWrapper = styled.div<{ $iconSize: string }>`
  display: flex;
  align-items: center;

  svg {
    font-size: ${({ $iconSize }) => $iconSize || "2.2rem"};
    color: ${({ theme }) => theme.primary};
    margin-right: 15px;
  }

  p {
    font-weight: 500;
    line-height: 1.4;
  }
`;

const ContactBlock: FC<Props> = ({
  headline,
  icon,
  children,
  className,
  iconSize,
  href,
}) => (
  <StyledWrapper
    className={className}
    href={href}
    rel="noopener"
    target="_blank"
  >
    <StyledHeadline>{headline}</StyledHeadline>
    <StyledContentWrapper $iconSize={iconSize}>
      <Icon icon={icon} />
      <p>{children}</p>
    </StyledContentWrapper>
  </StyledWrapper>
);

interface Props {
  headline: string;
  icon: any;
  className?: string;
  iconSize?: string;
  href: string;
}

export default ContactBlock;
