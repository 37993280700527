import React, { FC } from "react";
import styled, { keyframes } from "styled-components";
import { Icon } from "@iconify/react";
import loadingIcon from "@iconify/icons-uiw/loading";
import { motion, AnimatePresence } from "framer-motion";
import { easeInOutCirc } from "@theme/easing";

const loadingAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  
  100% {
    transform: rotate(360deg);
  }
`;

const StyledWrapper = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  background: ${({ theme }) => theme.foreground};
  width: 100%;
  height: 100vh;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 4.8rem;

  svg {
    animation: 0.5s linear ${loadingAnimation} infinite;
  }
`;

const Loader: FC<Props> = ({ loaded, setLoaderExited }) => (
  <AnimatePresence onExitComplete={() => setLoaderExited(true)}>
    {!loaded && (
      <StyledWrapper
        exit={{ y: "100%" }}
        transition={{ duration: 1.5, ease: easeInOutCirc }}
      >
        <motion.div
          exit={{ scale: 0.6, opacity: 0 }}
          transition={{ duration: 1, ease: [0.85, 0, 0.15, 1] }}
        >
          <Icon icon={loadingIcon} />
        </motion.div>
      </StyledWrapper>
    )}
  </AnimatePresence>
);

interface Props {
  loaded: boolean;
  setLoaderExited: React.Dispatch<React.SetStateAction<boolean>>;
}

export default Loader;
