import React, { createContext } from "react";
import { Scrollbar } from "smooth-scrollbar/scrollbar";
import SwiperCore from "swiper";
import { ImagesListType } from "react-spring-lightbox";
import SweetScroll from "sweet-scroll";

interface Context {
  isLoading: boolean;
  isLoaderExited: boolean;
  smoothScrollbar: Scrollbar;
  sweetScroll: SweetScroll;
}

interface Lightbox {
  swiper?: SwiperCore;
  setSwiper: React.Dispatch<React.SetStateAction<SwiperCore>>;
  isLightboxActive: boolean;
  setLightboxActive: React.Dispatch<React.SetStateAction<boolean>>;
  currentImage: number;
  setCurrentImage: React.Dispatch<React.SetStateAction<number>>;
  images: ImagesListType;
  setImages: React.Dispatch<React.SetStateAction<ImagesListType>>;
}

interface LightboxContextType {
  visualizationsLightbox: Lightbox;
  interiorLightbox: Lightbox;
}

export const MainContext = createContext<Context>({
  isLoading: null,
  isLoaderExited: null,
  smoothScrollbar: null,
  sweetScroll: null,
});

export const LightboxContext = createContext<LightboxContextType>({
  visualizationsLightbox: {
    isLightboxActive: null,
    currentImage: null,
    setLightboxActive: null,
    swiper: null,
    setCurrentImage: null,
    setSwiper: null,
    images: null,
    setImages: null,
  },
  interiorLightbox: {
    isLightboxActive: null,
    currentImage: null,
    setLightboxActive: null,
    swiper: null,
    setCurrentImage: null,
    setSwiper: null,
    images: null,
    setImages: null,
  },
});
