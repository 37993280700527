import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect } from "react";
import { Scrollbar } from "smooth-scrollbar/scrollbar";
import { useWindowSize } from "react-use";

gsap.registerPlugin(ScrollTrigger);

const getProps = (dataset: DOMStringMap) => ({
  scrollTrigger: dataset.scrollTrigger || "element",
  scrollDirection: dataset.scrollDirection || "top",
  scrollDistance: dataset.scrollDistance || 120,
  scrollStart: dataset.scrollStart || "top bottom",
});

export const useParallax = (smoothScrollbar: Scrollbar) => {
  const { width } = useWindowSize();

  useEffect(() => {
    if (typeof smoothScrollbar?.scrollTop === "number") {
      ScrollTrigger.scrollerProxy("body", {
        scrollTop() {
          return smoothScrollbar.scrollTop;
        },
      });
    }
  }, [smoothScrollbar]);

  useEffect(() => {
    const elements = document.querySelectorAll("[data-scroll]");

    elements.forEach((element) => {
      const el = element as HTMLElement;
      const { scrollTrigger, scrollDirection, scrollDistance, scrollStart } =
        getProps(el.dataset);

      ScrollTrigger.matchMedia({
        "(min-width: 1025px)": () =>
          gsap.to(el, {
            scrollTrigger: {
              scrub: 0.5,
              trigger: scrollTrigger === "element" ? el : scrollTrigger,
              scroller: document.body,
              start: scrollStart,
              end: `bottom top`,
            },
            ease: "none",
            y: scrollDirection === "bottom" ? scrollDistance : -scrollDistance,
          }),
        "(max-width: 1024px)": () =>
          gsap.set(el, {
            y: 0,
          }),
      });
    });
  }, [smoothScrollbar?.scrollTop]);
};
