import React, { FC } from "react";
import LogoSvg from "@assets/svg/logo.svg";

const Logo: FC<Props> = ({ className }) => <LogoSvg className={className} />;

interface Props extends React.SVGProps<SVGElement> {
  className?: string;
}

export default Logo;
