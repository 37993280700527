import { ScrollbarPlugin } from "smooth-scrollbar";

export default class LoadingPlugin extends ScrollbarPlugin {
  static pluginName = "loading";

  static defaultOptions = {
    loading: false,
  };

  transformDelta(delta: never) {
    return this.options.loading ? { x: 0, y: 0 } : delta;
  }
}
