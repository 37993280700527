import React, { FC, MouseEventHandler, useContext } from "react";
import { easingFunction } from "@theme/easing";
import { MainContext } from "@context/index";
import { navigate } from "gatsby";

const ScrollTo: FC<React.HTMLProps<HTMLAnchorElement>> = ({
  children,
  ...props
}) => {
  const { smoothScrollbar, sweetScroll } = useContext(MainContext);

  const { href } = props;

  const onClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();

    const target = href.includes("/") ? href.replace("/", "") : href;

    if (href === "/") {
      if (smoothScrollbar?.scrollTo) {
        smoothScrollbar?.scrollTo(0, 0, 1500, {
          easing: easingFunction,
        });
      } else {
        sweetScroll?.to(0);
      }

      return undefined;
    }

    const elementTarget = document.querySelector(target);

    props.onClick && props.onClick(e);

    if (elementTarget) {
      const distanceFromTop =
        typeof window !== "undefined" &&
        (smoothScrollbar?.scrollTop || window.scrollY) +
          elementTarget.getBoundingClientRect().top;

      if (smoothScrollbar?.scrollTo) {
        smoothScrollbar?.scrollTo(0, distanceFromTop - 80, 2000, {
          easing: easingFunction,
        });
      } else {
        sweetScroll?.to(distanceFromTop);
      }
    } else {
      navigate("/", {
        state: {
          scrollTo: target,
        },
      });
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <a {...(props as any)} onClick={onClick}>
      {children}
    </a>
  );
};

export default ScrollTo;
