import React, { FC, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { graphql, useStaticQuery, navigate, Link } from "gatsby";
import Overlay from "@assets/svg/overlay_2.svg";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Legend from "@components/atoms/Legend/Legend";
import Checkbox from "@components/atoms/Checkbox/Checkbox";
import {
  Availability,
  House,
  InteractiveVisualization as RendProInteractiveVisualization,
} from "@rendpro/core-ui";
import { moveIcon, zoomInIcon, zoomOutIcon } from "@assets/icons";
import Box from "@components/molecules/InteractiveVisualization/Box";
import { useWindowSize } from "react-use";
import {
  BOX_COORDS,
  VECTOR,
} from "@components/molecules/InteractiveVisualization/data";
import { ChildImageSharp } from "../../../types/queryTypes";

const StyledWrapper = styled.div`
  position: relative;
  z-index: 1;
  padding-top: 90px;

  @media (max-width: 1024px) {
    padding-bottom: 0;
  }

  @media (max-width: 720px) {
    padding-top: 40px;
  }
`;

const StyledBackground = styled.div`
  background: ${({ theme }) => theme.primaryDarker};
  left: 0;
  top: 0;
  width: 100%;
  height: 90%;
  position: absolute;
  z-index: -1;
  display: flex;
  align-items: flex-end;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const StyledOverlay = styled(Overlay)`
  width: 100%;
  height: 60%;
`;

const StyledInnerWrapper = styled.div`
  max-width: 1550px;
  width: 90%;
  margin: auto;

  @media (max-width: 1024px) {
    max-width: 700px;
  }
`;

const StyledHeadline = styled.h2`
  font-size: 4.2rem;
  color: #fff;
  margin: 0 0 60px;
  text-align: center;
  font-weight: 700;

  @media (max-width: 1024px) {
    color: inherit;
  }

  @media (max-width: 720px) {
    font-size: 3.2rem;
    margin: 0 0 40px;
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
`;

const StyledMobileNavItem = styled(Link)`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  border-radius: 50%;
  line-height: 1;
  margin-right: 10px;

  &.second_indication {
    &-available {
      background: ${({ theme }) => theme[Availability.Available]};
    }

    &-reserved {
      background: ${({ theme }) => theme[Availability.Reserved]};
    }

    &-unavailable {
      background: ${({ theme }) => theme[Availability.Unavailable]};
    }
  }

  @media (max-width: 1024px) {
    width: 40px;
    height: 40px;
  }
`;

const StyledIndication = styled(StyledMobileNavItem)`
  position: absolute;
  z-index: 20;
  font-size: 1.4rem;
  margin-right: 0;

  &.indication {
    &-available {
      background: ${({ theme }) => theme[Availability.Available]};
    }

    &-reserved {
      background: ${({ theme }) => theme[Availability.Reserved]};
    }

    &-unavailable {
      background: ${({ theme }) => theme[Availability.Unavailable]};
    }
  }

  @media (max-width: 1024px) {
    width: 20px;
    height: 20px;
    font-size: 1rem;
  }

  @media (max-width: 820px) {
    width: 10px;
    height: 10px;
    font-size: 0.4rem;
  }

  :nth-of-type(1) {
    left: 10.5%;
    top: 61%;
  }

  :nth-of-type(2) {
    left: 12.5%;
    top: 52%;
  }

  :nth-of-type(3) {
    left: 15%;
    top: 45%;
  }

  :nth-of-type(4) {
    left: 17%;
    top: 39%;
  }

  :nth-of-type(5) {
    left: 19%;
    top: 33%;
  }

  :nth-of-type(6) {
    left: 20.5%;
    top: 28%;
  }

  :nth-of-type(7) {
    left: 22%;
    top: 22%;
  }

  :nth-of-type(8) {
    left: 18%;
    top: 22%;
  }

  :nth-of-type(9) {
    left: 11%;
    top: 22%;
  }

  :nth-of-type(10) {
    left: 6.5%;
    top: 22%;
  }

  :nth-of-type(11) {
    left: 9%;
    top: 14.5%;
  }

  :nth-of-type(12) {
    left: 13%;
    top: 14.5%;
  }

  :nth-of-type(13) {
    left: 18%;
    top: 14.5%;
  }

  :nth-of-type(14) {
    left: 22%;
    top: 14.5%;
  }

  :nth-of-type(15) {
    left: 27%;
    top: 14.5%;
  }

  :nth-of-type(16) {
    left: 31%;
    top: 14.5%;
  }

  :nth-of-type(17) {
    left: 36%;
    top: 14.5%;
  }

  :nth-of-type(18) {
    left: 32.5%;
    top: 21.5%;
  }

  :nth-of-type(19) {
    left: 36.5%;
    top: 21.5%;
  }

  :nth-of-type(20) {
    left: 30%;
    top: 30%;
  }

  :nth-of-type(21) {
    left: 29.5%;
    top: 35%;
  }

  :nth-of-type(22) {
    left: 27%;
    top: 43%;
  }

  :nth-of-type(23) {
    left: 26.5%;
    top: 49%;
  }

  :nth-of-type(24) {
    left: 38%;
    top: 35.5%;
  }

  :nth-of-type(25) {
    left: 38%;
    top: 30%;
  }

  :nth-of-type(26) {
    left: 36%;
    top: 49%;
  }

  :nth-of-type(27) {
    left: 37%;
    top: 43%;
  }

  :nth-of-type(28) {
    left: 23%;
    top: 61%;
  }

  :nth-of-type(29) {
    left: 29%;
    top: 61%;
  }

  :nth-of-type(30) {
    left: 37%;
    top: 61%;
  }

  :nth-of-type(31) {
    left: 43.5%;
    top: 61%;
  }

  :nth-of-type(32) {
    left: 52%;
    top: 61%;
  }

  :nth-of-type(33) {
    left: 58%;
    top: 61%;
  }

  :nth-of-type(34) {
    left: 48%;
    top: 48%;
  }

  :nth-of-type(35) {
    left: 48%;
    top: 43%;
  }

  :nth-of-type(36) {
    left: 48%;
    top: 36%;
  }

  :nth-of-type(37) {
    left: 48%;
    top: 30%;
  }

  :nth-of-type(38) {
    left: 57%;
    top: 25%;
  }

  :nth-of-type(39) {
    left: 61%;
    top: 25%;
  }

  :nth-of-type(40) {
    left: 67%;
    top: 25%;
  }

  :nth-of-type(41) {
    left: 72%;
    top: 25%;
  }

  :nth-of-type(42) {
    left: 77.5%;
    top: 26%;
  }

  :nth-of-type(43) {
    left: 82.5%;
    top: 26.5%;
  }

  :nth-of-type(44) {
    left: 55.5%;
    top: 35%;
  }

  :nth-of-type(45) {
    left: 60.5%;
    top: 35%;
  }

  :nth-of-type(46) {
    left: 72%;
    top: 35%;
  }

  :nth-of-type(47) {
    left: 77%;
    top: 35%;
  }

  :nth-of-type(48) {
    left: 56.5%;
    top: 45%;
  }

  :nth-of-type(49) {
    left: 62%;
    top: 45%;
  }

  :nth-of-type(50) {
    left: 70.5%;
    top: 45%;
  }

  :nth-of-type(51) {
    left: 76%;
    top: 45%;
  }

  :nth-of-type(52) {
    left: 85%;
    top: 41%;
  }

  :nth-of-type(53) {
    left: 84.5%;
    top: 35.5%;
  }

  :nth-of-type(54) {
    left: 80.5%;
    top: 55%;
  }

  :nth-of-type(55) {
    left: 86%;
    top: 53.5%;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  @media (max-width: 430px) {
    width: 140px;
  }
`;

const InteractiveVisualization: FC = () => {
  const { file, allDatoCmsHouse } = useStaticQuery<{
    file: ChildImageSharp;
    allDatoCmsHouse: { nodes: House[] };
  }>(query);
  const { width } = useWindowSize();
  const { primary } = useContext(ThemeContext);

  const sortedData = allDatoCmsHouse.nodes.sort((prev, next) => {
    const prevNumber = parseFloat(
      prev.number.replace("A", ".1").replace("B", ".2")
    );
    const nextNumber = parseFloat(
      next.number.replace("A", ".1").replace("B", ".2")
    );

    if (prevNumber > nextNumber) {
      return 1;
    }

    if (prevNumber < nextNumber) {
      return -1;
    }

    return 0;
  });

  return (
    <StyledWrapper data-scroll="" id="interactive_visualization">
      <StyledInnerWrapper>
        <StyledHeadline>
          Wybierz interesujący Cię budynek, aby zobaczyć szczegóły oferty
        </StyledHeadline>

        <RendProInteractiveVisualization
          data={sortedData}
          subpagePrefix="/lokal"
          navigate={navigate}
          vector={VECTOR}
          image={() => (
            <StyledImage
              image={getImage(file.childImageSharp)}
              alt="Osiedle Zielone Wzgórza"
            />
          )}
          legend={Legend}
          box={Box}
          innerWidth={width}
          boxCoords={BOX_COORDS}
          checkbox={StyledCheckbox}
          color={primary}
          icons={{
            move: moveIcon,
            zoomIn: zoomInIcon,
            zoomOut: zoomOutIcon,
          }}
          indication={StyledIndication as any}
          mobileNavItem={StyledMobileNavItem as any}
          // animation={{ mobileNavItemsInSameTime: true }}
        />
      </StyledInnerWrapper>

      <StyledBackground>
        <StyledOverlay />
      </StyledBackground>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    file(name: { eq: "interactive_visualization" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }

    allDatoCmsHouse(sort: { order: ASC, fields: [number] }) {
      nodes {
        area
        availability
        deliveryDate
        garage
        number
        parcelArea
        area
        roomsCount
        rooms {
          name
          area
        }
        price
      }
    }
  }
`;

export default InteractiveVisualization;
