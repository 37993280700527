import { createGlobalStyle } from "styled-components";
import normalize from "styled-normalize";

export default createGlobalStyle`
    ${normalize}

    *, *::before, *::after {
        box-sizing: border-box;       
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html {
      font-size: 10px;
    }

    body {
      font-size: 1.6rem;
      font-family: 'Helvetica Neue', 'Roboto', sans-serif;
      color: #191E1C;
      height: 100vh;
    }
    
    h2 {
      font-family: 'Cardo', serif;
      font-weight: 400;
      font-size: 7.2rem;
      
      @media (max-width: 1340px) {
        font-size: 5.2rem;
      }
      
      @media (max-width: 720px) {
        font-size: 3.2rem;
      }
    }
    
    #scroll-wrapper {
      width: 100%;
      height: 100vh;
      position: static!important;  
    }
    
    .scrollbar-track {
      z-index: 99999999!important;
    }

    .fb_dialog_content iframe, .fb_dialog {
      z-index: 99!important;
    }
`;
