import React, { forwardRef } from "react";
import styled, { css } from "styled-components";
import { ChangeHandler } from "react-hook-form";

const StyledWrapper = styled.label<{ $error?: string }>`
  position: relative;
  display: block;
  transition: color 0.2s ease-in-out;
  color: ${({ theme }) => theme.washLight};

  textarea {
    width: 100%;
    border: 0;
    outline: none;
    font-size: 1.6rem;
    padding: 0 0 8px 0;
    border-bottom: 1px solid ${({ theme }) => theme.washLight};
    transition: border-bottom 0.2s ease-in-out;
    background: transparent;
    height: 100px;
    resize: none;
    color: ${({ theme }) => theme.washLight};
  }

  span {
    position: absolute;
    font-size: 1.6rem;
    width: 100%;
    left: 0;
    top: 0;
    pointer-events: none;
    transform-origin: left top;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    font-weight: 400;
  }

  textarea:not(:placeholder-shown) ~ span {
    transform: scale(0.6);
    opacity: 0;
  }
  ${({ $error }) =>
    $error &&
    css`
      color: #ff6a6a;
      textarea {
        border-bottom: 1px solid #ff6a6a;
        color: #ff6a6a;
      }
    `}
`;

const StyledError = styled.div`
  font-size: 1.2rem;
  display: block;
  margin-top: 10px;
  pointer-events: none;
  user-select: none;
`;

const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  ({ label, className, onChange, onBlur, name, error }, ref) => (
    <StyledWrapper className={className} $error={error}>
      <textarea
        placeholder=" "
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
      />
      <span>{label}</span>
      {error && <StyledError>{error}</StyledError>}
    </StyledWrapper>
  )
);

interface Props {
  label: string;
  className?: string;
  onChange?: ChangeHandler;
  onBlur?: ChangeHandler;
  name?: string;
  error?: string;
}

export default Textarea;
