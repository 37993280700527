export const windowIcon = {
  body: `
<path d="M72.5132 91.3H66.4312C66.0277 91.3 65.6407 91.1398 65.3553 90.8546C65.0699 90.5694 64.9095 90.1825 64.9092 89.779V1.526C64.9087 1.32579 64.9476 1.12745 65.0239 0.942327C65.1002 0.757208 65.2122 0.588951 65.3536 0.447197C65.4949 0.305442 65.6629 0.192974 65.8478 0.116237C66.0327 0.0394992 66.231 -6.91415e-07 66.4312 0L72.5172 0C72.9202 0.00211671 73.3058 0.163953 73.5896 0.450019C73.8735 0.736085 74.0323 1.12303 74.0312 1.526V89.776C74.0317 90.1793 73.8722 90.5663 73.5875 90.8521C73.3029 91.1378 72.9165 91.2989 72.5132 91.3ZM67.9482 88.256H70.9922V3.047H67.9482V88.256Z" fill="currentColor"/>
<path d="M66.4281 91.298C66.2303 91.2984 66.0343 91.26 65.8512 91.185L32.3761 77.491C32.0966 77.3765 31.8574 77.1813 31.6891 76.9304C31.5208 76.6795 31.431 76.3842 31.4312 76.082V15.22C31.4309 14.9179 31.5206 14.6225 31.689 14.3715C31.8573 14.1206 32.0965 13.9254 32.3761 13.811L65.8512 0.117019C66.0824 0.0218268 66.3335 -0.0148845 66.5823 0.010135C66.8311 0.0351545 67.0699 0.121133 67.2776 0.260458C67.4852 0.399784 67.6553 0.588158 67.7728 0.808903C67.8903 1.02965 67.9516 1.27596 67.9511 1.52602V89.776C67.9513 89.976 67.912 90.1741 67.8355 90.3588C67.759 90.5436 67.6468 90.7115 67.5053 90.8529C67.3638 90.9943 67.1959 91.1063 67.0111 91.1827C66.8262 91.2591 66.6281 91.2983 66.4281 91.298ZM34.4742 75.061L64.9052 87.51V3.79202L34.4742 16.241V75.061Z" fill="currentColor"/>
<path d="M60.342 82.64C60.1327 82.6399 59.9256 82.5964 59.734 82.512L38.434 73.2C38.1629 73.0814 37.9322 72.8864 37.7701 72.6389C37.608 72.3913 37.5214 72.1019 37.521 71.806V19.499C37.5212 19.203 37.6076 18.9136 37.7698 18.666C37.9319 18.4184 38.1628 18.2234 38.434 18.105L59.734 8.79302C59.9655 8.69187 60.2185 8.64997 60.4703 8.67111C60.722 8.69225 60.9645 8.77576 61.1759 8.9141C61.3873 9.05244 61.5609 9.24126 61.681 9.46349C61.8011 9.68573 61.864 9.93439 61.864 10.187V81.118C61.8641 81.3179 61.8249 81.5159 61.7484 81.7006C61.672 81.8853 61.5599 82.0532 61.4185 82.1945C61.2771 82.3359 61.1093 82.448 60.9246 82.5244C60.7399 82.6009 60.5419 82.6401 60.342 82.64ZM40.563 70.81L58.821 78.792V12.512L40.563 20.494V70.81Z" fill="currentColor"/>
<path d="M51.2111 53.259C51.0179 53.2596 50.8264 53.223 50.6471 53.151C50.4614 53.077 50.2921 52.967 50.1489 52.8275C50.0057 52.688 49.8914 52.5217 49.8125 52.338C49.7337 52.1543 49.6918 51.9568 49.6893 51.7569C49.6868 51.557 49.7238 51.3586 49.7981 51.173L52.8411 43.565C52.954 43.2825 53.149 43.0404 53.4008 42.8698C53.6527 42.6992 53.9499 42.608 54.2541 42.608H55.7761C56.1796 42.608 56.5666 42.7683 56.852 43.0536C57.1373 43.339 57.2976 43.726 57.2976 44.1295C57.2976 44.533 57.1373 44.92 56.852 45.2053C56.5666 45.4907 56.1796 45.651 55.7761 45.651H55.2841L52.6251 52.302C52.512 52.5845 52.3168 52.8267 52.0648 52.9973C51.8128 53.1678 51.5154 53.259 51.2111 53.259Z" fill="currentColor"/>
<path d="M14.6942 22.828C12.8887 22.8278 11.1238 22.2922 9.62264 21.289C8.12151 20.2858 6.95158 18.86 6.26078 17.1918C5.56998 15.5237 5.38934 13.6882 5.7417 11.9174C6.09406 10.1466 6.96359 8.52009 8.24034 7.24348C9.5171 5.96687 11.1437 5.09751 12.9146 4.74535C14.6854 4.39319 16.5209 4.57403 18.1889 5.26501C19.857 5.95599 21.2827 7.12608 22.2857 8.62732C23.2888 10.1285 23.8242 11.8935 23.8242 13.699C23.8239 16.1203 22.8619 18.4423 21.1497 20.1543C19.4375 21.8662 17.1154 22.828 14.6942 22.828ZM14.6942 7.61301C13.2857 7.61201 11.9205 8.09946 10.8312 8.99228C9.74195 9.8851 8.99597 11.128 8.72045 12.5093C8.44493 13.8905 8.65692 15.3245 9.32028 16.5669C9.98364 17.8094 11.0573 18.7833 12.3584 19.3228C13.6594 19.8623 15.1072 19.9339 16.4551 19.5254C17.803 19.117 18.9676 18.2537 19.7503 17.0828C20.533 15.9119 20.8855 14.5058 20.7476 13.1041C20.6097 11.7024 19.99 10.392 18.9942 9.39601C17.8537 8.25544 16.3071 7.61415 14.6942 7.61301Z" fill="currentColor"/>
<path d="M14.6931 7.61202C14.4933 7.61202 14.2954 7.57264 14.1108 7.49614C13.9262 7.41964 13.7585 7.30752 13.6173 7.16617C13.476 7.02483 13.364 6.85704 13.2876 6.67239C13.2112 6.48773 13.172 6.28984 13.1721 6.09002V1.52602C13.1721 1.12249 13.3324 0.73549 13.6178 0.450154C13.9031 0.164817 14.2901 0.0045166 14.6936 0.0045166C15.0971 0.0045166 15.4841 0.164817 15.7695 0.450154C16.0548 0.73549 16.2151 1.12249 16.2151 1.52602V6.09002C16.2151 6.49368 16.0548 6.8808 15.7693 7.16623C15.4839 7.45166 15.0968 7.61202 14.6931 7.61202Z" fill="currentColor"/>
<path d="M14.6931 27.393C14.4933 27.393 14.2954 27.3536 14.1108 27.2771C13.9262 27.2006 13.7585 27.0885 13.6173 26.9472C13.476 26.8058 13.364 26.638 13.2876 26.4534C13.2112 26.2687 13.172 26.0708 13.1721 25.871V21.307C13.1721 20.9035 13.3324 20.5165 13.6178 20.2312C13.9031 19.9458 14.2901 19.7855 14.6936 19.7855C15.0971 19.7855 15.4841 19.9458 15.7695 20.2312C16.0548 20.5165 16.2151 20.9035 16.2151 21.307V25.871C16.2151 26.2747 16.0548 26.6618 15.7693 26.9472C15.4839 27.2327 15.0968 27.393 14.6931 27.393Z" fill="currentColor"/>
<path d="M10.8902 8.63202C10.6229 8.63207 10.3603 8.56178 10.1287 8.4282C9.8972 8.29462 9.70491 8.10245 9.57117 7.87102L7.28917 3.91502C7.11641 3.56835 7.08233 3.16885 7.19386 2.79793C7.30538 2.427 7.55414 2.11255 7.88943 1.91864C8.22473 1.72473 8.62134 1.66595 8.99846 1.75428C9.37559 1.8426 9.70485 2.07138 9.91917 2.39402L12.2012 6.35002C12.3344 6.58121 12.4045 6.84337 12.4045 7.11022C12.4045 7.37706 12.3343 7.6392 12.201 7.87036C12.0677 8.10152 11.8759 8.29357 11.645 8.42725C11.414 8.56093 11.152 8.63155 10.8852 8.63202H10.8902Z" fill="currentColor"/>
<path d="M20.7772 25.761C20.5098 25.7613 20.2471 25.6911 20.0156 25.5575C19.784 25.4239 19.5917 25.2316 19.4582 25L17.1762 21.044C16.9745 20.6946 16.9198 20.2794 17.0243 19.8897C17.1287 19.5 17.3837 19.1677 17.7332 18.966C18.0826 18.7643 18.4978 18.7097 18.8875 18.8141C19.2772 18.9186 19.6095 19.1736 19.8112 19.523L22.0942 23.479C22.2274 23.7102 22.2975 23.9724 22.2975 24.2392C22.2975 24.506 22.2273 24.7682 22.094 24.9994C21.9607 25.2305 21.7689 25.4226 21.538 25.5563C21.307 25.6899 21.045 25.7605 20.7782 25.761H20.7772Z" fill="currentColor"/>
<path d="M8.10415 11.416C7.83728 11.4176 7.57474 11.3486 7.34315 11.216L3.39215 8.92999C3.04695 8.72597 2.7962 8.39397 2.6944 8.00613C2.5926 7.61828 2.64797 7.20594 2.84847 6.85868C3.04897 6.51143 3.37841 6.25733 3.7652 6.15159C4.15198 6.04585 4.56487 6.09703 4.91415 6.29399L8.87015 8.57599C9.15976 8.74375 9.38601 9.0023 9.51385 9.31162C9.6417 9.62094 9.66402 9.96377 9.57736 10.2871C9.49071 10.6103 9.2999 10.896 9.03449 11.0999C8.76907 11.3038 8.44384 11.4146 8.10915 11.415L8.10415 11.416Z" fill="currentColor"/>
<path d="M25.237 21.306C24.9702 21.3073 24.7077 21.2383 24.476 21.106L20.52 18.823C20.347 18.7231 20.1954 18.5902 20.0737 18.4317C19.9521 18.2732 19.8629 18.0923 19.8112 17.8994C19.7594 17.7064 19.7462 17.5051 19.7723 17.3071C19.7983 17.109 19.8632 16.918 19.963 16.745C20.0629 16.572 20.1959 16.4203 20.3543 16.2987C20.5128 16.1771 20.6937 16.0878 20.8867 16.0361C21.0796 15.9844 21.2809 15.9712 21.4789 15.9972C21.677 16.0233 21.868 16.0881 22.041 16.188L25.997 18.47C26.2871 18.6375 26.5139 18.8961 26.642 19.2056C26.7702 19.5152 26.7927 19.8583 26.706 20.1819C26.6193 20.5055 26.4282 20.7915 26.1625 20.9954C25.8967 21.1994 25.571 21.31 25.236 21.31L25.237 21.306Z" fill="currentColor"/>
<path d="M7.08608 15.22H2.51808C2.11456 15.22 1.72756 15.0597 1.44222 14.7744C1.15688 14.489 0.996582 14.102 0.996582 13.6985C0.996582 13.295 1.15688 12.908 1.44222 12.6226C1.72756 12.3373 2.11456 12.177 2.51808 12.177H7.08608C7.48961 12.177 7.87661 12.3373 8.16195 12.6226C8.44728 12.908 8.60758 13.295 8.60758 13.6985C8.60758 14.102 8.44728 14.489 8.16195 14.7744C7.87661 15.0597 7.48961 15.22 7.08608 15.22Z" fill="currentColor"/>
<path d="M26.867 15.22H22.302C21.8985 15.22 21.5115 15.0597 21.2262 14.7744C20.9408 14.489 20.7805 14.102 20.7805 13.6985C20.7805 13.295 20.9408 12.908 21.2262 12.6226C21.5115 12.3373 21.8985 12.177 22.302 12.177H26.867C27.2705 12.177 27.6575 12.3373 27.9429 12.6226C28.2282 12.908 28.3885 13.295 28.3885 13.6985C28.3885 14.102 28.2282 14.489 27.9429 14.7744C27.6575 15.0597 27.2705 15.22 26.867 15.22Z" fill="currentColor"/>
<path d="M4.15319 21.306C3.81818 21.306 3.49253 21.1954 3.22676 20.9915C2.96098 20.7875 2.76992 20.5016 2.68321 20.178C2.5965 19.8544 2.61898 19.5112 2.74717 19.2017C2.87535 18.8922 3.10208 18.6336 3.39219 18.466L7.34819 16.184C7.69761 15.9823 8.11285 15.9277 8.50255 16.0322C8.89225 16.1366 9.22449 16.3916 9.42619 16.741C9.62789 17.0905 9.68252 17.5057 9.57806 17.8954C9.4736 18.2851 9.21861 18.6173 8.86919 18.819L4.91319 21.102C4.68223 21.2357 4.42006 21.3061 4.15319 21.306Z" fill="currentColor"/>
<path d="M21.284 11.416C20.9493 11.4156 20.6241 11.3049 20.3587 11.101C20.0933 10.8971 19.9025 10.6114 19.8158 10.2881C19.7292 9.96481 19.7515 9.62197 19.8793 9.31266C20.0072 9.00334 20.2334 8.74479 20.523 8.57703L24.479 6.29503C24.8283 6.09807 25.2412 6.04689 25.628 6.15263C26.0148 6.25836 26.3442 6.51246 26.5447 6.85972C26.7452 7.20697 26.8006 7.61932 26.6988 8.00716C26.597 8.39501 26.3462 8.72701 26.001 8.93103L22.045 11.213C21.8137 11.3467 21.5512 11.4167 21.284 11.416Z" fill="currentColor"/>
<path d="M8.60698 25.762C8.34014 25.7615 8.07811 25.6909 7.84716 25.5572C7.61622 25.4235 7.42448 25.2315 7.29118 25.0003C7.15787 24.7692 7.08768 24.507 7.08765 24.2402C7.08761 23.9733 7.15773 23.7112 7.29098 23.48L9.57298 19.524C9.67285 19.351 9.80582 19.1993 9.9643 19.0777C10.1228 18.9561 10.3037 18.8668 10.4966 18.8151C10.6896 18.7634 10.8908 18.7502 11.0889 18.7762C11.287 18.8023 11.478 18.8671 11.651 18.967C11.824 19.0669 11.9756 19.1998 12.0973 19.3583C12.2189 19.5168 12.3081 19.6977 12.3598 19.8906C12.4116 20.0836 12.4248 20.2848 12.3987 20.4829C12.3727 20.681 12.3078 20.872 12.208 21.045L9.92998 25.001C9.79598 25.2331 9.60307 25.4257 9.37076 25.5593C9.13845 25.6929 8.87498 25.7629 8.60698 25.762Z" fill="currentColor"/>
<path d="M18.4981 8.63198C18.2312 8.63151 17.9692 8.5609 17.7383 8.42722C17.5073 8.29353 17.3156 8.10148 17.1823 7.87033C17.049 7.63917 16.9788 7.37702 16.9788 7.11018C16.9787 6.84333 17.0488 6.58117 17.1821 6.34998L19.4641 2.39398C19.6681 2.04878 20.0001 1.79804 20.388 1.69623C20.7758 1.59443 21.1881 1.6498 21.5354 1.8503C21.8827 2.0508 22.1368 2.38024 22.2425 2.76703C22.3482 3.15382 22.2971 3.56671 22.1001 3.91598L19.8181 7.87198C19.6841 8.10331 19.4915 8.29531 19.2598 8.42871C19.0282 8.56211 18.7654 8.63221 18.4981 8.63198Z" fill="currentColor"/>
<path d="M45.125 35.001C44.7218 35.0003 44.3353 34.84 44.05 34.555L28.834 19.339C28.5624 19.051 28.4136 18.6685 28.4193 18.2727C28.4251 17.8768 28.5848 17.4988 28.8647 17.2187C29.1446 16.9387 29.5226 16.7788 29.9184 16.7729C30.3143 16.767 30.6969 16.9155 30.985 17.187L46.198 32.403C46.4115 32.6158 46.5569 32.8871 46.616 33.1826C46.6751 33.4782 46.6451 33.7846 46.5299 34.063C46.4147 34.3415 46.2193 34.5795 45.9687 34.7468C45.7181 34.9142 45.4234 35.0033 45.122 35.003L45.125 35.001Z" fill="currentColor"/>
<path d="M45.125 51.737C44.9253 51.7369 44.7276 51.6975 44.5431 51.621C44.3587 51.5444 44.1911 51.4323 44.05 51.291L28.834 36.076C28.5657 35.7874 28.4198 35.406 28.427 35.012C28.4342 34.618 28.5939 34.2422 28.8725 33.9636C29.1512 33.6849 29.527 33.5252 29.921 33.518C30.315 33.5108 30.6964 33.6567 30.985 33.925L46.198 49.14C46.4114 49.3528 46.5569 49.6241 46.616 49.9196C46.6751 50.2151 46.6451 50.5215 46.5299 50.8C46.4146 51.0785 46.2193 51.3165 45.9687 51.4838C45.718 51.6511 45.4234 51.7403 45.122 51.74L45.125 51.737Z" fill="currentColor"/>
<path d="M45.125 68.474C44.9253 68.4739 44.7276 68.4345 44.5431 68.358C44.3587 68.2814 44.1911 68.1693 44.05 68.028L28.834 52.813C28.5657 52.5244 28.4198 52.143 28.427 51.749C28.4342 51.355 28.5939 50.9792 28.8725 50.7005C29.1512 50.4219 29.527 50.2622 29.921 50.255C30.315 50.2478 30.6964 50.3937 30.985 50.662L46.198 65.877C46.4114 66.0897 46.5569 66.3611 46.616 66.6566C46.6751 66.9521 46.6451 67.2585 46.5299 67.537C46.4146 67.8155 46.2193 68.0534 45.9687 68.2208C45.718 68.3881 45.4234 68.4773 45.122 68.477L45.125 68.474Z" fill="currentColor"/>
<path d="M87.728 57.823C87.3248 57.8226 86.9381 57.6622 86.653 57.377L71.437 42.161C71.1687 41.8724 71.0228 41.491 71.03 41.097C71.0372 40.703 71.197 40.3272 71.4756 40.0486C71.7542 39.7699 72.13 39.6102 72.524 39.603C72.918 39.5958 73.2994 39.7417 73.588 40.01L88.804 55.226C89.0175 55.4387 89.163 55.7101 89.2221 56.0056C89.2811 56.3011 89.2512 56.6075 89.1359 56.886C89.0207 57.1645 88.8254 57.4025 88.5747 57.5698C88.3241 57.7371 88.0294 57.8263 87.728 57.826V57.823Z" fill="currentColor"/>
<path d="M87.728 74.561C87.5283 74.5611 87.3305 74.5217 87.1461 74.4452C86.9616 74.3686 86.794 74.2564 86.653 74.115L71.437 58.899C71.1687 58.6104 71.0228 58.2289 71.03 57.835C71.0372 57.441 71.197 57.0652 71.4756 56.7865C71.7542 56.5079 72.13 56.3482 72.524 56.341C72.918 56.3338 73.2994 56.4797 73.588 56.748L88.804 71.963C89.0175 72.1757 89.163 72.4471 89.2221 72.7426C89.2811 73.0381 89.2512 73.3445 89.1359 73.623C89.0207 73.9015 88.8254 74.1394 88.5747 74.3068C88.3241 74.4741 88.0294 74.5633 87.728 74.563V74.561Z" fill="currentColor"/>
<path d="M87.728 91.298C87.5283 91.2979 87.3306 91.2585 87.1462 91.1819C86.9617 91.1054 86.7941 90.9933 86.653 90.852L71.437 75.637C71.1654 75.349 71.0166 74.9665 71.0224 74.5706C71.0281 74.1747 71.1879 73.7967 71.4678 73.5167C71.7476 73.2367 72.1256 73.0768 72.5215 73.0708C72.9173 73.0649 73.2999 73.2135 73.588 73.485L88.804 88.7C89.0175 88.9127 89.163 89.1841 89.2221 89.4796C89.2811 89.7751 89.2512 90.0815 89.1359 90.36C89.0207 90.6384 88.8254 90.8765 88.5747 91.0438C88.3241 91.2112 88.0294 91.3003 87.728 91.3V91.298Z" fill="currentColor"/>
`,
  width: 90,
  height: 92,
};
