export const BOX_COORDS = {
  "1A": {
    y: "68%",
    x: "14%",
  },
  "1B": {
    y: "58%",
    x: "16%",
  },
  "2A": {
    y: "50%",
    x: "18%",
  },
  "2B": {
    y: "42%",
    x: "22%",
  },
  "3A": {
    y: "36%",
    x: "23%",
  },
  "3B": {
    y: "30%",
    x: "25%",
  },
  "4A": {
    y: "24%",
    x: "26%",
  },
  "4B": {
    y: "24%",
    x: "20%",
  },
  "5A": {
    y: "24%",
    x: "14%",
  },
  "5B": {
    y: "24%",
    x: "8%",
  },
  "6A": {
    y: "18%",
    x: "12%",
  },
  "6B": {
    y: "18%",
    x: "17%",
  },
  "7A": {
    y: "18%",
    x: "21%",
  },
  "7B": {
    y: "18%",
    x: "25%",
  },
  "8A": {
    y: "18%",
    x: "30%",
  },
  "8B": {
    y: "18%",
    x: "35%",
  },
  "9": {
    y: "18%",
    x: "39%",
  },
  "10A": {
    y: "26%",
    x: "36%",
  },
  "10B": {
    y: "26%",
    x: "42%",
  },
  "11A": {
    y: "32%",
    x: "35%",
  },
  "11B": {
    y: "39%",
    x: "33%",
  },
  "12A": {
    y: "46%",
    x: "32%",
  },
  "12B": {
    y: "54%",
    x: "30%",
  },
  "13A": {
    y: "38%",
    x: "42%",
  },
  "13B": {
    y: "34%",
    x: "42%",
  },
  "14A": {
    y: "54%",
    x: "41%",
  },
  "14B": {
    y: "46%",
    x: "42%",
  },
  "15A": {
    y: "66%",
    x: "26%",
  },
  "15B": {
    y: "66%",
    x: "33%",
  },
  "16A": {
    y: "66%",
    x: "41%",
  },
  "16B": {
    y: "66%",
    x: "48%",
  },
  "17A": {
    y: "66%",
    x: "55%",
  },
  "17B": {
    y: "66%",
    x: "62%",
  },
  "18A": {
    y: "51%",
    x: "53%",
  },
  "18B": {
    y: "46%",
    x: "53%",
  },
  "19A": {
    y: "40%",
    x: "52%",
  },
  "19B": {
    y: "32%",
    x: "52%",
  },
  "20A": {
    y: "28%",
    x: "60%",
  },
  "20B": {
    y: "28%",
    x: "64%",
  },
  "21A": {
    y: "28%",
    x: "33%",
    fromRight: true,
  },
  "21B": {
    y: "28%",
    x: "29%",
    fromRight: true,
  },
  "22A": {
    y: "28%",
    x: "23%",
    fromRight: true,
  },
  "22B": {
    y: "28%",
    x: "18%",
    fromRight: true,
  },
  "23A": {
    y: "40%",
    x: "58%",
  },
  "23B": {
    y: "40%",
    x: "64%",
  },
  "24A": {
    y: "40%",
    x: "31%",
    fromRight: true,
  },
  "24B": {
    y: "40%",
    x: "24%",
    fromRight: true,
  },
  "25A": {
    y: "52%",
    x: "58%",
  },
  "25B": {
    y: "52%",
    x: "64%",
  },
  "26A": {
    y: "52%",
    x: "30%",
    fromRight: true,
  },
  "26B": {
    y: "52%",
    x: "24%",
    fromRight: true,
  },
  "27A": {
    y: "46%",
    x: "16%",
    fromRight: true,
  },
  "27B": {
    y: "40%",
    x: "16%",
    fromRight: true,
  },
  "28A": {
    y: "60%",
    x: "20%",
    fromRight: true,
  },
  "28B": {
    y: "60%",
    x: "12%",
    fromRight: true,
  },
};

export const VECTOR = {
  width: 5120,
  height: 2880,
  paths: [
    {
      number: "1A",
      path: "m288.06 2025 523.64 13.12 122.64-290.34-116.93 3.4224-15.401-46.774-133.48-41.64-104.96 40.499-12.549 21.105-117.5 3.4224 5.7041 11.408z",
    },
    {
      number: "1B",
      path: "m934.33 1747.7-116.93 3.4224-15.401-46.774-133.48-41.64-104.96 40.499-12.549 21.105-90.401 1.5457 119.39-201.27 438.83 0.4033 10.084 31.057-94.591 191.65",
    },
    {
      number: "2A",
      path: "m 1130.41,1314.082 -17.3975,-0.2852 -6.5597,-26.5241 -79.5722,0.713 -6.9875,-21.3903 -113.79684,-37.0767 -94.97326,38.5027 -10.83779,21.1052 -109.51872,2.2816 9.98217,26.2389 -120.74464,206.981 438.83478,0.4033 -2.6534,-8.026 21.533,-44.7772 27.8074,-0.2852 3.0887,-3.2167 59.2346,-134.4804 -4.1122,-9.6597 z",
    },
    {
      number: "2B",
      path: "m 1130.41,1314.082 30.4047,-68.9691 8.0668,0.8067 43.5608,-99.2218 -7.8651,-0.2017 -2.6217,-9.6801 -285.36361,2.6217 -2.21837,-10.0836 -9.47851,-1.6133 -3.42839,5.6468 2.0167,6.4534 -97.4068,0.8067 -88.42946,147.9085 82.63815,0.5704 10.83779,-21.1052 94.97326,-38.5027 113.79684,37.0767 6.9875,21.3903 79.5722,-0.713 6.5597,26.5241 z",
    },
    {
      number: "3A",
      path: "m 808.91266,1135.1872 87.20142,-153.79682 -7.98574,-24.52763 101.81819,-1.42603 9.69697,-19.96435 86.9875,-36.50624 100.3922,35.08022 5.4189,19.96435 69.0196,-0.85562 7.1301,26.80927 13.4268,1.36374 1.4117,8.26848 -42.2182,97.03443 -26.2388,1.7112 -15.4011,35.0803 1.426,8.1283 -285.41466,2.9459 -1.21002,-5.1426 -9.47851,-1.6133 -3.42839,5.6468 0.60501,2.3192 z",
    },
    {
      number: "3B",
      path: "m 1282.0186,981.32809 -13.4268,-1.36374 -7.1301,-26.80927 -69.0196,0.85562 -5.4189,-19.96435 -100.3922,-35.08022 -86.9875,36.50624 -9.69697,19.96435 -77.38804,0.27923 71.79465,-122.81727 94.58346,-1.61336 v -3.83174 l -2.4201,-0.20167 -0.4033,-3.42839 3.2267,-4.03341 7.8651,0.20167 1.4117,10.08352 263.3816,-4.03341 1.21,9.07517 z",
    },
    {
      number: "4A",
      path: "m 1387.0889,638.89178 41.1408,25.41046 3.2267,8.8735 v 11.29354 l -54.8544,133.5058 -4.0334,-18.15034 -9.2768,-0.80668 -2.4201,6.45345 2.4201,7.26014 -3.1492,7.94571 -5.4189,-0.1426 -0.5704,1.42603 -265.9399,4.28277 -1.0084,-6.25178 -7.8651,-0.20167 -3.2267,4.03341 0.4033,3.42839 -39.3257,0.40334 -6.2518,-19.36035 29.4439,-55.66103 -4.2351,-21.57873 13.3709,-76.79489 16.2567,-5.9893 h 32.2282 l 5.9893,-6.84492 11.6934,0.2852 -4.5633,-20.24955 55.3298,-0.57041 16.8271,-12.26382 19.7094,10.24153 55.8901,-0.38445 102.6763,-2.82338 z",
    },
    {
      number: "4B",
      path: "m 1131.123,621.46168 -128.057,0.85561 -21.39042,-9.69697 -17.9328,11.63909 -61.782,0.48212 7.55794,23.52942 -1.14082,5.7041 -25.66845,-0.28521 -109.23351,178.53833 204.77718,3.42246 4.20678,-7.13013 54.7309,-0.86525 -6.2518,-19.36035 29.4439,-55.66103 -4.2351,-21.57873 13.3709,-76.79489 16.2567,-5.9893 h 32.2282 l 5.9893,-6.84492 11.6934,0.2852 -4.5633,-20.24955 v 0",
    },
    {
      number: "5A",
      path: "m 877.14795,654.97326 -112.01051,178.32877 -327.10937,5.24343 6.45346,-17.747 45.5775,-58.48441 -8.06681,-22.58708 17.34365,-76.23141 25.41047,-6.05011 46.38419,-0.40334 8.47015,-4.03341 15.32695,-0.80668 -7.26013,-20.97372 39.93073,-0.40334 17.747,-13.31025 23.39376,10.89021 179.48664,-4.84009 -2.0167,4.43674 7.26013,27.02384 z",
    },
    {
      number: "5B",
      path: "m 438.02807,838.54546 -283.14522,5.64677 154.07618,-180.29333 26.21715,1.61337 4.43675,-7.66348 -10.48686,-25.00713 77.84476,-0.80668 16.53698,-10.48686 17.74699,8.06682 102.04521,-0.80668 0.40334,3.22672 43.96415,-0.80668 7.26013,20.97372 -15.32695,0.80668 -8.47015,4.03341 -46.38419,0.40334 -25.41047,6.05011 -17.34365,76.23141 8.06681,22.58708 -45.5775,58.48441 z",
    },
    {
      number: "6A",
      path: "M 377.61141,583.52941 486.27451,451.4795 h 3.27986 l 0.71301,-3.85027 h 7.98574 l 0.57041,3.27986 43.63636,-31.08734 72.58467,32.37076 4.42068,0.14261 -15.97148,72.44206 4.56328,17.39751 -22.24599,27.95009 3.42246,13.40463 -56.21869,0.60886 -147.42105,5.94927 -2.62171,-4.84009 h -5.24343 z",
    },
    {
      number: "6B",
      path: "m 589.23351,583.52941 194.86094,-2.7187 6.05011,-11.09188 50.01426,-1.00835 79.19947,-121.36645 -105.38324,1.28342 -67.87879,-32.22816 -44.49198,29.94652 -4.56328,-17.96791 -5.4189,-0.28521 -18.25311,24.81284 -53.90375,-0.57041 -15.97148,72.44206 4.56328,17.39751 -22.24599,27.95009 3.42246,13.40463 v 0",
    },
    {
      number: "7A",
      path: "m 840.21391,579.82175 222.45989,-3.99287 -2.8521,-12.26382 15.6863,-28.8057 -4.2781,-19.67915 11.123,-70.73083 h -14.5454 L 1002.2103,412.40642 960,442.35294 l -8.55615,-0.85561 0.28521,5.13369 h -25.38325 z",
    },
    {
      number: "7B",
      path: "m 1062.6738,575.82888 188.8057,-0.28521 0.8556,-4.56328 8.5562,-0.2852 2.852,-7.98574 42.4956,-1.14082 57.6114,-121.78253 -96.9697,0.2852 -62.4599,-29.94652 -52.1925,33.08378 -69.8753,1.14082 -11.123,70.73083 4.2781,19.67915 -15.6863,28.8057 z",
    },
    {
      number: "8A",
      path: "m 1311.2299,560.57041 55.4724,-119.9287 34.3672,-1.71123 3.1373,-2.13903 -0.2852,-2.42425 5.1337,-0.4278 41.4973,-26.38147 65.3119,30.80214 22.246,-0.2852 -8.5561,71.01604 3.4224,20.24955 -8.2709,21.96079 0.5704,16.82709 -197.3619,6.84492 -0.9233,-4.44774 -8.2685,0.20167 -1.5125,-9.27684 z",
    },
    {
      number: "8B",
      path: "m 1525.2763,568.12834 h 189.9465 v -3.99287 h 9.1266 l 3.4224,-9.69697 33.6542,-0.2852 33.0838,-120.64171 h -82.139 l -61.0339,-30.80214 -51.9073,33.65419 -61.3191,1.71123 -8.5561,71.01604 3.4224,20.24955 -8.2709,21.96079 z",
    },
    {
      number: "9",
      path: "m 1765.6328,554.3672 h 12.4777 l 0.5704,8.27095 11.5508,0.2852 0.2852,3.42246 419.2514,-7.91444 9.1265,-131.7647 -253.262,5.13369 -63.3155,-32.51337 -57.6114,35.36542 -47.2727,-1.06952 z",
    },
    {
      number: "10A",
      path: "m 1603.0779,634.6567 -76.0298,35.69566 -39.9893,110.2537 43.066,22.24599 281.7825,-4.84848 -2.5668,-21.39038 6.8449,-29.37611 -1.426,-24.52763 3.7076,-70.44563 5.9893,-7.70054 16.2567,0.57041 2.5669,-15.68627 -80.4278,2.56684 -43.6364,-21.67558 -38.5027,23.10161 z",
    },
    {
      number: "10B",
      path: "m 1811.9073,798.00357 350.8059,-4.63228 1.6134,-12.90691 30.6539,-1.21002 11.6968,-155.28619 -225.0641,4.0334 -43.5608,-21.7804 -39.5274,23.79711 -55.2451,-0.57086 -2.5669,15.68627 -16.2567,-0.57041 -5.9893,7.70054 -3.7076,70.44563 1.426,24.52763 -6.8449,29.37611 z",
    },
    {
      number: "11A",
      path: "m 1827.9404,874.24111 -34.284,128.66569 -119.7922,2.2184 -0.4034,-22.58709 -106.4819,-35.49399 -84.7016,38.31737 -7.6635,21.78041 -59.6944,1.6134 4.4368,17.747 -24.2005,-0.4034 -7.6635,-20.167 38.3174,-106.07865 93.9784,-40.33408 5.6468,20.57038 33.0739,-2.82338 93.1717,-0.40334 -1.6133,-5.64677 2.8234,-4.03341 7.2601,0.40334 1.21,8.47016 z",
    },
    {
      number: "11B",
      path: "m 1793.6564,1002.9068 -0.8067,4.6384 2.2184,21.1754 -42.4516,160.2271 -10.5877,-0.7058 -4.0334,22.9904 -409.5105,2.6001 9.6969,-21.1051 -9.1265,-25.0981 -15.4011,-0.5704 56.4706,-134.6167 2.0405,0.1103 1.815,3.8317 13.5119,-30.4522 7.6635,20.167 24.2005,0.4034 -4.4368,-17.747 59.6944,-1.6134 7.6635,-21.78041 84.7016,-38.31737 106.4819,35.49399 0.4034,22.58709 z",
    },
    {
      number: "12A",
      path: "m 1737.9954,1211.2323 -6.0976,29.7565 9.4421,0.6786 -41.8345,157.917 -11.0919,0.2017 1.4261,-5.7041 -134.1543,3.4938 -1.21,-22.9905 -123.0189,-36.3006 -93.9784,39.5274 -9.2769,20.167 -67.3579,-0.4033 3.2268,22.587 h -32.6706 l -8.0669,-24.2004 68.9713,-182.7134 36.1811,0.5834 409.5105,-2.6001 -6.0976,29.7565 9.4421,0.6786 -41.8345,157.917",
    },
    {
      number: "12B",
      path: "m 1689.8396,1394.082 -1.4261,5.7041 5.1337,25.6684 -61.6042,236.1498 -149.4475,2.852 -6.6716,-23.2627 h -6.4535 l -7.6635,22.9904 -312.9012,0.8427 -13.1194,-27.3796 11.4082,-23.3868 -19.9644,-0.5705 96.2024,-217.7274 8.0669,24.2004 h 32.6706 l -3.2268,-22.587 67.3579,0.4033 9.2769,-20.167 93.9784,-39.5274 123.0189,36.3006 1.21,22.9905 z",
    },
    {
      number: "13A",
      path: "m 2202.3529,1030.1604 -13.0192,131.8644 -19.3604,0.4033 -4.8401,46.3842 -336.3862,2.42 2.4201,-24.2004 -76.2314,2.0167 42.7541,-156.8996 -4.8401,-24.6038 109.4676,-1.9124 5.7041,-19.39394 92.4064,-37.07665 97.5401,37.07665 2.8521,19.39394 69.59,-1.7112 2.2816,24.5276 29.6613,1.7112 v 0",
    },
    {
      number: "13B",
      path: "m 2162.7132,866.37597 55.661,39.52739 -16.0213,124.25704 -29.6613,-1.7112 -2.2816,-24.5276 -69.59,1.7112 -2.8521,-19.39394 -97.5401,-37.07665 -92.4064,37.07665 -5.7041,19.39394 h -97.5401 l 32.5134,-132.33512 121.4973,-1.14082 1.1408,-4.56328 h 8.5562 v 4.56328 l 159.7147,-2.85205 1.1409,11.4082 30.2317,-0.57041 -0.5704,-11.4082 z",
    },
    {
      number: "14A",
      path: "m 2155.4531,1422.1795 -16.9861,185.2358 -24.5276,2.2817 -1.1408,23.3868 2.852,28.5205 H 1631.943 l 61.6042,-236.1498 -5.1337,-25.6684 132.2667,-2.2103 4.8401,-18.9571 105.272,-40.7374 110.112,38.3174 3.2267,20.167 81.4748,1.6134 1.6134,25.8138 28.2339,-1.6134",
    },
    {
      number: "14B",
      path: "m 2165.1332,1208.8123 1.8151,22.3854 13.1085,-1.0084 -24.6037,191.9902 -28.2339,1.6134 -1.6134,-25.8138 -81.4748,-1.6134 -3.2267,-20.167 -110.112,-38.3174 -105.272,40.7374 -4.8401,18.9571 -121.1748,2.0086 41.8345,-157.917 -9.4421,-0.6786 10.131,-52.7469 89.1383,-1.21 -2.4201,24.2004 z",
    },
    {
      number: "15A",
      path: "m 1472.0856,1641.1406 -2.7152,0.053 -7.6635,22.9904 -312.9012,0.8427 -6.8449,2.8521 -22.6738,0.1426 -136.4706,331.2656 h 4.56328 l 9.98218,29.0909 -6.55972,8.8414 3.99287,1.7112 0.57041,5.7041 288.34228,8.2709 1.9964,-8.5561 64.2774,3.4115 2.4201,-11.6969 -5.2434,-23.3938 22.9904,-73.0047 -4.8401,-22.587 18.5537,-128.6657 37.914,-49.2076 17.9386,0.545 z",
    },
    {
      number: "15B",
      path: "m 1820.1783,1661.6043 -337.6828,2.852 -6.6716,-23.2627 -3.7383,-0.053 -32.3708,98.6096 -17.9386,-0.545 -37.914,49.2076 -18.5537,128.6657 4.8401,22.587 -22.9904,73.0047 5.2434,23.3938 -2.4201,11.6969 63.7279,-0.4034 -1.6134,7.2602 302.9089,10.8902 5.6468,-26.2172 h 7.6635 z",
    },
    {
      number: "16A",
      path: "m 1728.3152,2039.2907 26.8363,-1.501 4.8485,23.1016 4.8485,1.7112 0.2852,5.4189 348.2353,5.9893 1.7112,-94.1176 -4.5633,-51.9074 5.7041,-124.9197 9.1266,-49.0553 7.9857,-0.5704 9.1266,-92.9768 -26.8093,1.1408 h -295.4723 z",
    },
    {
      number: "16B",
      path: "m 2149.5038,1664.184 340.0506,-1.7241 -4.1686,378.2427 -41.3424,1.0084 -1.6134,19.3603 -11.4952,0.2017 -0.4033,6.0501 -317.1625,6.6876 -2.8521,-146.025 5.7041,-124.9197 9.1266,-49.0553 7.9857,-0.5704 9.1266,-92.9768 z",
    },
    {
      number: "17A",
      path: "m 2494.461,1662.5706 338.6045,-2.42 2.0168,-18.1503 2.0167,-0.8067 9.2768,110.112 7.2601,0.8067 9.6802,48.4009 6.4535,137.1358 -0.8067,15.327 5.6467,73.0047 -0.4033,24.6038 4.4368,11.6968 -80.6682,-2.0167 v 7.2602 l -259.7515,0.4033 v -7.6635 h -10.4868 v -18.957 l -39.3257,-0.605 z",
    },
    {
      number: "17B",
      path: "m 2878.6431,2062.2813 82.2125,0.036 -0.4278,3.9929 h 234.0311 l -0.605,-5.6468 10.8902,-0.8067 -0.605,-19.1587 28.3743,-1.4869 -68.7084,-379.0651 -321.1668,0.5981 -2.6654,-18.9501 -2.8738,-0.605 9.2768,110.112 7.2601,0.8067 9.6802,48.4009 6.4535,137.1358 -0.8067,15.327 5.6467,73.0047 -0.4033,24.6038 z",
    },
    {
      number: "18A",
      path: "m 2766.111,1574.239 -419.0711,8.8735 -2.0167,-46.7875 -36.704,1.6133 0.4034,42.7542 -31.864,-34.284 12.5036,-136.3292 h 34.6873 l 0.8067,-22.1837 79.4581,-2.4201 1.21,-21.7804 104.8686,-38.7207 110.5154,37.1074 1.6134,22.1837 135.5225,0.4034 z",
    },
    {
      number: "18B",
      path: "m 2758.0442,1384.6689 -15.327,-174.2433 -423.1044,1.2101 0.8066,19.3603 -16.5369,-1.21 -14.5203,180.2933 h 34.6873 l 0.8067,-22.1837 79.4581,-2.4201 1.21,-21.7804 104.8686,-38.7207 110.5154,37.1074 1.6134,22.1837 z",
    },
    {
      number: "19A",
      path: "m 2320.5707,1208.5098 419.8215,-0.4528 -12.1953,-165.4211 3.6301,-1.21 -1.6133,-24.2005 h -120.5989 v -20.57036 l -99.6252,-35.09065 -89.5417,37.10735 -1.6133,16.53696 -72.6014,3.6301 0.8067,21.7804 -32.2672,-0.8067 -11.6969,139.9592 16.1336,2.0167 z",
    },
    {
      number: "19B",
      path: "m 2730.2137,1017.2254 -3.2268,-51.42593 18.957,-0.60501 -10.2576,-104.4458 -78.7166,-36.50623 -276.0784,-2.28164 -52.4777,39.35828 -13.6409,178.49343 32.2672,0.8067 -0.8067,-21.7804 72.6014,-3.6301 1.6133,-16.53696 89.5417,-37.10735 99.6252,35.09065 v 20.57036 h 120.5989 v 0",
    },
    {
      number: "20A",
      path: "m 3107.3373,915.98688 -157.1769,1.80278 -4.1594,-15.31469 -29.6456,1.00835 -0.2017,35.69566 -70.3783,-36.79039 -33.0838,-206.48841 241.8539,3.42246 3.3825,-24.33187 4.4368,0.20167 4.6384,24.60379 10.4869,50.4176 12.1002,-0.80668 6.4534,8.47015 4.8401,81.87818 -0.8067,19.36035 6.8568,33.07395 z",
    },
    {
      number: "20B",
      path: "m 3069.4232,700.60291 241.6012,3.02506 45.3758,199.85535 -16.537,0.40334 -0.605,10.08352 -5.4451,0.80668 -0.2017,3.63007 -226.2741,-2.42005 -0.4034,-23.7971 -6.8568,-33.07395 0.8067,-19.36035 -4.8401,-81.87818 -6.4534,-8.47015 -12.1002,0.80668 -10.4869,-50.4176 z",
    },
    {
      number: "21A",
      path: "m 3635.508,920.64171 -65.0267,1.71123 -160.5704,-2.85205 v -3.70766 h -7.1301 l -3.7077,-12.54902 -38.2175,0.85561 -44.492,-199.9287 240.1426,2.85205 4.5633,-24.24242 h 4.5633 l 7.7005,24.24242 16.5419,49.91088 13.9751,0.2852 15.9715,10.26738 8.2709,78.14617 -1.9964,24.52763 11.4082,30.23173 z",
    },
    {
      number: "21B",
      path: "m 3891.3369,916.36364 -50.4813,-202.78075 -267.5223,-6.55972 16.5419,49.91088 13.9751,0.2852 15.9715,10.26738 8.2709,78.14617 -1.9964,24.52763 11.4082,30.23173 -1.9965,20.24955 67.5936,0.57041 0.8556,4.27808 161.1408,5.41889 0.5705,-3.99287 h 5.9893 l 1.9964,-10.55258 z",
    },
    {
      number: "22A",
      path: "m 4095.3205,704.03131 28.0322,73.60969 23.7971,2.0167 18.352,10.8902 7.8651,80.86983 -4.2351,21.7804 10.5474,35.99973 -3.4225,16.25669 -64.4563,-2.28164 -2.8521,-3.99287 -11.9786,-0.57041 -0.8556,4.56328 -151.1587,-9.41177 -1.7112,-4.56328 -5.1337,-0.57041 -5.4189,-12.54902 -41.3547,0.28521 -50.4813,-202.78075 246.4171,16.54189 z",
    },
    {
      number: "22B",
      path: "M 4598.0749,999.9287 4470.0178,754.93761 4108.0927,731.5508 4097.3372,704.03131 h -2.0167 l 28.0322,73.60969 23.7971,2.0167 18.352,10.8902 7.8651,80.86983 -4.2351,21.7804 10.5474,35.99973 -3.4225,16.25669 71.5864,2.28164 1.1409,6.55971 108.9483,6.55972 7.1301,-13.68984 30.8021,0.57041 6.2745,13.40463 -9.1265,26.23886 z",
    },
    {
      number: "23A",
      path: "m 3001.964,975.061 -244.3232,-0.99305 -2.0167,34.28395 -24.9289,0.5608 1.1318,32.5132 13.1086,169.2014 1.815,43.7625 302.9089,-1.21 -19.6417,-108.367 0.5704,-21.6755 -13.1194,-94.6881 -9.1265,-15.9715 -2.8521,-14.83064 h -3.9929 l 0.4667,-22.58606 v 0",
    },
    {
      number: "23B",
      path: "m 3049.6595,1253.1798 400.3158,1.815 -54.3247,-247.0804 -30.5786,-0.3692 -0.8067,-8.87346 3.6301,-27.83051 -315.6316,1.13738 -0.5704,3.42246 -49.7294,-0.34007 -0.4667,22.58606 h 3.9929 l 2.8521,14.83064 9.1265,15.9715 13.1194,94.6881 -0.5704,21.6755 z",
    },
    {
      number: "24A",
      path: "m 3458.3957,1240.6417 258.3957,10.8378 7.4154,-22.8164 h 3.4224 l 7.9858,20.5348 155.4134,8.0153 4.0334,-22.1838 -29.8472,-79.8614 4.4367,-21.3771 -11.6969,-95.1884 -27.4271,-16.1337 h -20.5704 l -10.8902,-13.7135 4.4367,-24.60383 -375.9098,-11.60245 -1.1408,25.66845 -1.1409,10.83783 -29.6613,-1.1409 56.4706,241.2835 z",
    },
    {
      number: "24B",
      path: "m 4091.4887,1032.149 47.5943,128.2624 34.8921,105.3284 -249.0661,-7.3166 -13.7136,-9.2768 -20.167,8.0668 4.0334,-22.1838 -29.8472,-79.8614 4.4367,-21.3771 -11.6969,-95.1884 -27.4271,-16.1337 h -20.5704 l -10.8902,-13.7135 4.4367,-24.60383 154.4795,4.43675 8.0669,-4.03341 11.2935,7.26014 89.945,2.82338 -8.0668,27.02387 2.8234,8.8735 z",
    },
    {
      number: "25A",
      path: "m 2766.9162,1527.8431 34.5098,0.1426 2.9947,15.5437 9.4117,-0.713 1.4261,6.5597 215.3297,-2.4242 0.2852,-5.1337 11.123,-0.8556 v 3.4224 l 59.2908,-1.6066 -7.2602,-27.8305 -8.4701,-52.4343 1.21,-22.1837 -7.2601,-103.2553 -18.5537,-31.4605 -11.2936,-52.4343 -302.9089,1.21 11.2936,130.2791 z",
    },
    {
      number: "25B",
      path: "m 3419.6079,1525.8467 -23.4786,-0.4119 -2.4201,16.9403 -6.4534,0.4033 1.6133,4.8401 -215.7873,-0.4033 -0.4033,-6.8568 h -10.4869 l -0.4033,4.4367 -60.5011,-2.0167 -7.2602,-27.8305 -8.4701,-52.4343 1.21,-22.1837 -7.2601,-103.2553 -18.5537,-31.4605 -11.2936,-52.4343 292.6578,4.5742 z",
    },
    {
      number: "26A",
      path: "m 3725.8467,1228.6988 23.2057,61.9917 h 14.5203 l 37.1073,31.0572 15.327,98.4151 -3.6301,26.6205 17.747,52.4343 -4.0334,28.2339 18.5537,37.1073 -66.2485,-19.3182 -292.6203,1.7113 -1.426,-6.8449 -11.123,0.5704 -5.4189,-15.1159 -48.1996,0.2852 -77.2906,-268.0927 112.656,2.5669 -2.8521,-11.123 6.2745,-8.5562 258.3957,10.8378 7.4154,-22.8164 z",
    },
    {
      number: "26B",
      path: "m 4175.787,1271.3301 57.6777,162.748 -13.7136,1.21 -10.4869,33.679 19.3604,17.747 -39.124,9.6801 -18.6628,-19.6028 -10.2674,29.091 -193.369,37.647 -17.6827,55.9002 -4.9911,-3.2799 -10.1247,2.9947 -23.1016,-12.549 -66.6571,-22.0366 -18.5537,-37.1073 4.0334,-28.2339 -17.747,-52.4343 3.6301,-26.6205 -15.327,-98.4151 -37.1073,-31.0572 h -14.5203 l -23.2057,-61.9917 1.7825,-0.036 7.9858,20.5348 155.4134,8.0153 20.167,-8.0668 13.7136,9.2768 9.7791,5.0349 z",
    },
    {
      number: "27A",
      path: "m 4715.4569,1470.9838 -123.4223,-260.5582 -34.2839,-2.42 7.2601,-19.3604 -62.5178,-10.0835 -9.6802,-25.4104 -87.9283,-41.9475 -119.3888,27.4272 -8.8735,20.167 -127.8591,-13.7136 -9.6801,15.327 100.4318,273.465 h 10.0835 l 14.9236,18.957 -5.6467,27.8306 158.7146,-31.6623 z",
    },
    {
      number: "27B",
      path: "m 4592.0346,1210.4256 -51.2242,-112.532 -50.0143,-26.6205 -1.6134,-27.4272 -183.52,-15.7303 3.6301,-10.8902 -1.6134,-4.4367 -8.8735,-0.4034 -4.0334,15.327 -52.4343,-4.8401 -4.0334,10.4868 -142.7826,-14.1169 -4.0335,12.9069 47.5943,128.2624 9.6801,-15.327 127.8591,13.7136 8.8735,-20.167 119.3888,-27.4272 87.9283,41.9475 9.6802,25.4104 62.5178,10.0835 -7.2601,19.3604 z",
    },
    {
      number: "28A",
      path: "m 4442.3529,1822.4599 -29.6613,5.1337 -10.2674,7.4153 -220.7486,46.2032 5.1337,-13.6898 -22.246,-27.3797 -30.8022,5.1337 -7.4153,26.8093 -184.2424,-235.5794 11.9786,-32.5133 -4.5633,-4.5633 17.6827,-55.9002 193.369,-37.647 10.2674,-29.091 21.6756,22.8164 51.9073,60.4635 21.1052,-5.7041 84.4206,40.4991 48.4849,111.23 -8.5562,23.9572 55.3298,65.5971 -7.9857,21.6756 z",
    },
    {
      number: "28B",
      path: "m 4829.0909,1746.5954 -10.2674,-15.9715 16.5419,-27.9501 -108.9483,-224.1711 -307.451,-24.5277 -226.4527,45.6328 51.9073,60.4635 21.1052,-5.7041 84.4206,40.4991 48.4849,111.23 -8.5562,23.9572 55.3298,65.5971 -7.9857,21.6756 5.1336,5.1337 92.4065,-22.8164 4.5632,8.5561 z",
    },
  ],
};
