export const WEBSITE_TITLE = "Osiedle Zielone Wzgórza | Mława";

export const NAVIGATION_ITEMS = [
  { name: "Start", href: "/" },
  { name: "O osiedlu", href: "/#about_estate" },
  { name: "Galeria", href: "/#visualizations" },
  { name: "Standard", href: "/#properties" },
  { name: "Lokalizacja", href: "/#location" },
  {
    name: "Interaktywny podgląd",
    href: "/#interactive_visualization",
    hideOnSmallWidth: true,
  },
  { name: "O deweloperze", href: "/#about_us" },
  { name: "Kontakt", href: "/#contact" },
];
