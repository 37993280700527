import { DefaultTheme } from "styled-components";
import { Availability } from "../types/availability";

export const theme: DefaultTheme = {
  primary: "#7DA698",
  primaryDarker: "#285A53",
  secondary: "#60B4D8",
  foreground: "#191E1C",
  background: "#fff",
  secondaryBackground: "#F4F4F4",
  washLight: "#DDDDDD",
  washMedium: "#bbb",
  washLighter: "#ececec",
  washHeavy: "#939393",
  [Availability.Available]: "#4CA800",
  [Availability.Reserved]: "#CC8500",
  [Availability.Unavailable]: "#E60000",
};
