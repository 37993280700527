export const fenceIcon = {
  body: `
<path d="M17.624 11.578H15.367C14.9161 11.578 14.4837 11.7571 14.1649 12.0759C13.8461 12.3947 13.667 12.8271 13.667 13.278C13.667 13.7289 13.8461 14.1613 14.1649 14.4801C14.4837 14.7989 14.9161 14.978 15.367 14.978H17.623C18.0739 14.978 18.5063 14.7989 18.8251 14.4801C19.1439 14.1613 19.323 13.7289 19.323 13.278C19.323 12.8271 19.1439 12.3947 18.8251 12.0759C18.5063 11.7571 18.0739 11.578 17.623 11.578H17.624Z" fill="currentColor"/>
<path d="M72.122 11.578H69.866C69.4152 11.578 68.9828 11.7571 68.6639 12.0759C68.3451 12.3947 68.166 12.8271 68.166 13.278C68.166 13.7289 68.3451 14.1613 68.6639 14.4801C68.9828 14.7989 69.4152 14.978 69.866 14.978H72.122C72.5729 14.978 73.0053 14.7989 73.3241 14.4801C73.6429 14.1613 73.822 13.7289 73.822 13.278C73.822 12.8271 73.6429 12.3947 73.3241 12.0759C73.0053 11.7571 72.5729 11.578 72.122 11.578Z" fill="currentColor"/>
<path d="M17.624 54.877H15.367C15.1437 54.877 14.9227 54.9209 14.7164 55.0063C14.5102 55.0918 14.3228 55.217 14.1649 55.3749C14.007 55.5327 13.8818 55.7201 13.7964 55.9264C13.711 56.1326 13.667 56.3537 13.667 56.577C13.667 56.8002 13.711 57.0213 13.7964 57.2275C13.8818 57.4338 14.007 57.6212 14.1649 57.779C14.3228 57.9369 14.5102 58.0621 14.7164 58.1476C14.9227 58.233 15.1437 58.2769 15.367 58.2769H17.623C18.0739 58.2769 18.5063 58.0978 18.8251 57.779C19.1439 57.4602 19.323 57.0278 19.323 56.577C19.323 56.1261 19.1439 55.6937 18.8251 55.3749C18.5063 55.0561 18.0739 54.877 17.623 54.877H17.624Z" fill="currentColor"/>
<path d="M72.122 54.877H69.866C69.4152 54.877 68.9828 55.0561 68.6639 55.3749C68.3451 55.6937 68.166 56.1261 68.166 56.577C68.166 57.0278 68.3451 57.4602 68.6639 57.779C68.9828 58.0978 69.4152 58.2769 69.866 58.2769H72.122C72.5729 58.2769 73.0053 58.0978 73.3241 57.779C73.6429 57.4602 73.822 57.0278 73.822 56.577C73.822 56.1261 73.6429 55.6937 73.3241 55.3749C73.0053 55.0561 72.5729 54.877 72.122 54.877Z" fill="currentColor"/>
<path d="M85.436 65.641H80.082V41.565C80.082 41.1141 79.9029 40.6817 79.5841 40.3629C79.2653 40.0441 78.8329 39.865 78.382 39.865C77.9311 39.865 77.4987 40.0441 77.1799 40.3629C76.8611 40.6817 76.682 41.1141 76.682 41.565V65.64H64.644V3.402H76.678V26.602C76.678 27.0529 76.8571 27.4853 77.1759 27.8041C77.4947 28.1229 77.9271 28.302 78.378 28.302C78.8289 28.302 79.2613 28.1229 79.5801 27.8041C79.8989 27.4853 80.078 27.0529 80.078 26.602V1.702C80.078 1.25113 79.8989 0.818732 79.5801 0.49992C79.2613 0.181108 78.8289 0.0019989 78.378 0.0019989H62.942C62.4911 0.0019989 62.0587 0.181108 61.7399 0.49992C61.4211 0.818732 61.242 1.25113 61.242 1.702V11.58H52.989V1.7C52.989 1.24913 52.8099 0.816729 52.4911 0.497917C52.1723 0.179105 51.7399 0 51.289 0H35.851C35.6278 0 35.4067 0.043973 35.2004 0.129406C34.9942 0.214839 34.8068 0.340058 34.6489 0.497917C34.4911 0.655777 34.3658 0.843185 34.2804 1.04944C34.195 1.25569 34.151 1.47675 34.151 1.7V11.578H25.9V1.7C25.9 1.24913 25.7209 0.816729 25.4021 0.497917C25.0833 0.179105 24.6509 0 24.2 0H8.758C8.30713 0 7.87473 0.179105 7.55592 0.497917C7.23711 0.816729 7.058 1.24913 7.058 1.7V65.64H1.7C1.24913 65.64 0.81673 65.8191 0.497918 66.1379C0.179107 66.4567 0 66.8891 0 67.34C0 67.7909 0.179107 68.2233 0.497918 68.5421C0.81673 68.8609 1.24913 69.04 1.7 69.04H85.436C85.8869 69.04 86.3193 68.8609 86.6381 68.5421C86.9569 68.2233 87.136 67.7909 87.136 67.34C87.136 66.8891 86.9569 66.4567 86.6381 66.1379C86.3193 65.8191 85.8869 65.64 85.436 65.64V65.641ZM52.989 58.282H61.24V65.641H52.989V58.282ZM61.24 14.982V54.882H52.989V14.982H61.24ZM25.9 58.281H34.151V65.64H25.9V58.281ZM34.151 14.981V54.881H25.9V14.981H34.151ZM10.46 65.641V3.402H22.494V65.641H10.46ZM37.553 65.641V3.402H49.585V65.641H37.553Z" fill="currentColor"/>
<path d="M44.772 11.578H42.516C42.0652 11.578 41.6328 11.7571 41.3139 12.0759C40.9951 12.3947 40.816 12.8271 40.816 13.278C40.816 13.7289 40.9951 14.1613 41.3139 14.4801C41.6328 14.7989 42.0652 14.978 42.516 14.978H44.772C45.2229 14.978 45.6553 14.7989 45.9741 14.4801C46.2929 14.1613 46.472 13.7289 46.472 13.278C46.472 12.8271 46.2929 12.3947 45.9741 12.0759C45.6553 11.7571 45.2229 11.578 44.772 11.578Z" fill="currentColor"/>
<path d="M44.772 54.877H42.516C42.0652 54.877 41.6328 55.0561 41.3139 55.3749C40.9951 55.6937 40.816 56.1261 40.816 56.577C40.816 57.0278 40.9951 57.4602 41.3139 57.779C41.6328 58.0978 42.0652 58.2769 42.516 58.2769H44.772C45.2229 58.2769 45.6553 58.0978 45.9741 57.779C46.2929 57.4602 46.472 57.0278 46.472 56.577C46.472 56.1261 46.2929 55.6937 45.9741 55.3749C45.6553 55.0561 45.2229 54.877 44.772 54.877Z" fill="currentColor"/>
<path d="M79.794 33.005C79.6018 32.7177 79.3266 32.4958 79.0052 32.3689C78.6837 32.242 78.3312 32.2161 77.9946 32.2946C77.658 32.3732 77.3533 32.5524 77.1213 32.8085C76.8892 33.0646 76.7406 33.3854 76.6955 33.7281C76.6503 34.0707 76.7107 34.419 76.8686 34.7265C77.0264 35.034 77.2742 35.286 77.579 35.4491C77.8837 35.6121 78.231 35.6784 78.5743 35.6391C78.9177 35.5997 79.241 35.4567 79.501 35.229C79.8089 34.9554 80.008 34.58 80.0618 34.1716C80.1156 33.7632 80.0206 33.349 79.794 33.005Z" fill="currentColor"/>
`,
  width: 88,
  height: 70,
};
