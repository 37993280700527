import React, { FC, useContext, useState } from "react";
import styled, { css } from "styled-components";
import Logo from "@components/atoms/Logo/Logo";
import { NAVIGATION_ITEMS } from "@config/main";
import { MainContext } from "@context/index";
import { motion } from "framer-motion";
import { cssEaseInOutCirc, cssEaseOutExpo, easeInOutCirc } from "@theme/easing";
import ScrollTo from "@components/atoms/ScrollTo/ScrollTo";
import { Link } from "gatsby";
import { Twirl as Hamburger } from "hamburger-react";

const StyledWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 85px;
  z-index: 99999;
  background: #fff;
  box-shadow: 0 5px 40px -20px rgba(0, 0, 0, 0.2);

  @media (min-width: 1025px) {
    .hamburger-react {
      display: none;
    }
  }

  @media (max-width: 720px) {
    height: 60px;
  }
`;

const StyledInnerWrapper = styled.div`
  width: 90%;
  max-width: 1730px;
  display: flex;
  align-items: center;
  margin: auto;
  height: 100%;
  justify-content: space-between;
`;

const StyledLogo = styled(Logo)`
  width: 70px;

  @media (max-width: 720px) {
    width: 50px;
  }
`;

const StyledListWrapper = styled.nav<{ $isHamburgerActive: boolean }>`
  @media (max-width: 1024px) {
    position: absolute;
    height: 100vh;
    height: calc(var(--vh) * 100);
    left: 0;
    top: 0;
    width: 100%;
    background: #fff;
    transform: translateY(-100%);
    transition: 2s transform ${cssEaseOutExpo};
    transform-origin: left top;
    z-index: -1;
    pointer-events: none;

    ${({ $isHamburgerActive }) =>
      $isHamburgerActive &&
      css`
        transform: translateY(0);
        pointer-events: all;
      `}
  }
`;

const StyledList = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
`;

const StyledItem = styled.li<{ $hideOnSmallWidth: boolean }>`
  margin-right: 38px;
  font-weight: 500;

  :last-of-type {
    margin-right: 0;
  }

  ${({ $hideOnSmallWidth }) =>
    $hideOnSmallWidth &&
    css`
      @media (min-width: 1025px) and (max-width: 1240px) {
        display: none;
      }
    `};

  @media (max-width: 1024px) {
    margin-right: 0;
    margin-bottom: 10px;
    font-size: 2.4rem;
    width: 100%;
    text-align: center;

    :last-of-type {
      margin-bottom: 0;
    }
  }
`;

const StyledScrollTo = styled(ScrollTo)`
  color: ${({ theme }) => theme.foreground};
  text-decoration: none;
  transition: color 0.5s ${cssEaseInOutCirc};
  position: relative;

  ::before {
    width: 90%;
    height: 2px;
    background: ${({ theme }) => theme.foreground};
    position: absolute;
    content: "";
    bottom: -4px;
    transition: transform 0.5s ${cssEaseOutExpo};
    transform: scaleX(0);
  }

  :hover::before {
    transform: scaleX(1);
  }

  @media (max-width: 1024px) {
    width: 100%;
    display: block;
    padding: 10px 0;
  }
`;

const Navigation: FC<Props> = ({ isAnotherThanIndex }) => {
  const { isLoading } = useContext(MainContext);
  const [isHamburgerActive, setHamburgerActive] = useState<boolean>(false);

  const handleItemClick = () => {
    setHamburgerActive(false);
  };

  return (
    <StyledWrapper
      initial={{ y: "-100%" }}
      animate={!isLoading && { y: 0 }}
      transition={{
        duration: 1,
        ease: easeInOutCirc,
        delay: !isAnotherThanIndex ? 0.8 : 0,
      }}
    >
      <StyledInnerWrapper>
        <Link to="/" aria-label="Prowadź do strony głównej">
          <StyledLogo />
        </Link>

        <StyledListWrapper $isHamburgerActive={isHamburgerActive}>
          <StyledList>
            {NAVIGATION_ITEMS.map(({ name, href, hideOnSmallWidth }) => (
              <StyledItem key={href} $hideOnSmallWidth={hideOnSmallWidth}>
                <StyledScrollTo href={href} onClick={() => handleItemClick()}>
                  {name}
                </StyledScrollTo>
              </StyledItem>
            ))}
          </StyledList>
        </StyledListWrapper>

        <Hamburger
          toggled={isHamburgerActive}
          toggle={setHamburgerActive}
          rounded
          label={!isHamburgerActive ? "Otwórz nawigację" : "Zamknij nawigację"}
          easing={cssEaseOutExpo}
        />
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

interface Props {
  isAnotherThanIndex: boolean;
}

export default Navigation;
