import React, { forwardRef } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import checkIcon from "@iconify/icons-fontisto/check";
import { ChangeHandler } from "react-hook-form";

const StyledWrapper = styled.label`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  user-select: none;
  position: relative;

  div:first-of-type {
    margin-right: 15px;
    position: relative;
    span {
      width: 20px;
      height: 20px;
      border: 1px solid ${({ theme }) => theme.primaryDarker};
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: #fff;
        font-size: 2.2rem;
      }
    }
    input:checked + span {
      background: ${({ theme }) => theme.primaryDarker};
      svg {
        color: #fff;
      }
    }
    input {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      z-index: 2;
      cursor: pointer;
    }
  }

  div:last-of-type {
    font-size: 1.6rem;
    line-height: 1.5;
    display: flex;
    align-items: flex-start;
    span {
      color: ${({ theme }) => theme.primaryDarker};
      margin-right: 5px;
    }
  }
`;

const StyledCheckbox = styled.span`
  pointer-events: none;
`;

const StyledInput = styled.input``;

const StyledError = styled.span`
  font-size: 1.2rem;
  color: #ff6a6a;
  pointer-events: none;
  margin-top: 10px;
  display: block;
`;

const Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    { label, id, className, onChange, checked, onBlur, name, error, ...props },
    ref
  ) => (
    <div className={className} {...props}>
      <StyledWrapper>
        <div>
          <StyledInput
            type="checkbox"
            id={id}
            onChange={onChange}
            checked={checked}
            ref={ref}
            name={name}
            onBlur={onBlur}
          />
          <StyledCheckbox>
            <Icon icon={checkIcon} />
          </StyledCheckbox>
        </div>
        <div>{label}</div>
      </StyledWrapper>
      {error && <StyledError>{error}</StyledError>}
    </div>
  )
);

interface Props {
  id?: string;
  className?: string;
  label: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  checked?: boolean;
  onBlur?: ChangeHandler;
  name?: string;
  error?: string;
}

export default Checkbox;
